.ProfileHeader .artist-avatar-wrapper {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
.profileSidebar .profile-menu-links-wrapper ul li a.active span {
	color: #00bbff;
}
.ProfileHeader .artist-avatar-wrapper .artist-img-wrapper {
	position: relative;
	overflow: hidden;
	height: 145px;
	width: 145px;
	border-radius: 50%;
}
.ProfileHeader .artist-avatar-wrapper .artist-img-wrapper button.changeimg {
	position: absolute;
	bottom: 0%;
	right: 0px;
	left: 0;
	background: #f2f2f240;
	border: 0px;
	font-family: var(--font-default);
	font-size: 9px;
	padding: 7px 0px;
	color: var(--default-text-color);
	text-decoration: underline;
	backdrop-filter: blur(16px);
}
.ProfileHeader .artist-avatar-wrapper img {
	height: 145px;
	width: 145px;
	border-radius: 50%;
	outline: 32px solid #05092c;
	margin: 0px auto;
}

.profile {
	padding: 50px 0px;
}
.ProfileHeader {
	background-image: url("../img/login-bg.png");
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	padding: 139px 0px 35px 0px;
}
.ProfileHeader .profie-avatar-wrapper {
	text-align: center;
}
.ProfileHeader .profie-avatar-wrapper img {
	height: 145px;
	width: 145px;
	border-radius: 50%;
	outline: 32px solid #05092c;
	margin: 0px auto;
}
.profileSidebar .profile-menu-links-wrapper ul {
	padding: 0px;
	list-style: none;
	margin: 0px;
}
.profileSidebar .profile-menu-links-wrapper {
	border: 1px solid #828496;
	border-radius: 10px;
	padding: 10px 0px;
}
.profileSidebar .profile-menu-links-wrapper ul li {
	border-bottom: 1px solid #828496;
	padding: 19px 24px;
}
.profileSidebar .profile-menu-links-wrapper ul li:last-child {
	border-bottom: 0px;
}
.profileSidebar .profile-menu-links-wrapper ul li a img {
	margin-right: 26px;
}
.profileSidebar .profile-menu-links-wrapper ul li a span {
	font-family: var(--font-semibold);
	font-size: 15px;
}
.profile .profile-logout-btn-wrapper {
	margin-top: 30px;
}
.profile .profile-logout-btn-wrapper a.btn {
	width: 100%;
	font-family: var(--font-default);
}
/* responsive starts here */
@media (max-width: 1024px) {
	.profileSidebar .profile-menu-links-wrapper ul li {
		padding: 19px 14px;
	}
}
@media (max-width: 768px) {
	.profile .profile-logout-btn-wrapper a.btn {
		margin-bottom: 40px;
	}
}
@media (max-width: 480px) {
	.profile .profileSidebar {
		margin-bottom: 42px;
	}
}
/* responsive ends here */
