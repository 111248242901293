/* Product Detail Css Start Here */
.product-detail {
	padding-top: 170px;
	padding-bottom: 150px;
	background-image: url("../img/login-bg.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top center;
}

.product-detail .video-sec {
	position: relative;
	border-radius: 10px;
	overflow: hidden;
}

.product-detail .about-product .pla-img-wrapper {
	position: absolute;
	right: 0;
	top: -2%;
}

.product-detail .about-product {
	position: relative;
}

.product-detail .video-timer {
	position: absolute;
	top: 15px;
	display: flex;
	align-items: center;
	left: 22px;
}

.product-detail .video-timer .icon svg {
	background: #004c66;
	width: 35px;
	height: 35px;
	padding: 7px 8px;
	border-radius: 50%;
	border: 5px solid #ffffffb0;
	cursor: pointer;
}

.product-detail .video-timer .time {
	margin: 0;
	font-size: 16px;
	font-family: "Vietnam-reg";
	padding-left: 15px;
}

/* Buttons Css Start Here */
.product-detail .video-sec .bottom-bar {
	display: flex;
	align-items: center;
	background-color: #181833;
	padding: 14px;
}

.product-detail .video-sec .bottom-bar .other-action {
	display: flex;
	margin-left: auto;
	gap: 6px;
}

.product-detail .video-sec .bottom-bar button {
	font-size: 11px;
	font-family: var(--font-default);
	color: var(--default-text-color);
}

.product-detail .video-sec .bottom-bar .printest button {
	background: var(--button-detail-back);
	display: flex;
	gap: 8px;
	padding: 6px 12px 6px 10px;
	align-items: center;
	border: 0px;
	border-radius: 5px;
}

.product-detail .video-sec .bottom-bar .other-action button {
	background: transparent;
	border: 0px;
	display: flex;
	gap: 6px;
	align-items: center;
}

/* details box Css Start Here */
.video-leftside .description {
	border: 1px solid #ffffff70;
	border-radius: 10px;
	padding: 25px 33px 31px 24px;
	margin-top: 36px;
}

.video-leftside .description .top-text h6 {
	font-size: 17px;
}

.video-leftside .description .details {
	margin-top: 40px;
}

.video-leftside .description .details h6 {
	font-size: 17px;
}

.product-detail .details .single-data {
	display: flex;
}

.product-detail .details .single-data .question {
	display: block;
	width: 53%;
	color: var(--extra-color-2);
	font-size: 13px;
	font-family: var(--font-default);
}

.product-detail .details .single-data .answer {
	display: block;
	width: 36%;
	color: var(--default-text-color);
	font-size: 12px;
	font-family: var(--font-light);
}

.product-detail .details .single-data.right-side .question {
	display: block;
	width: 64%;
}

/* Right side data */
.about-product .top-heading span {
	font-size: 14px;
}

.about-product .top-heading h2 {
	line-height: 1;
}

.about-product .product-data .id-availalbality {
	display: flex;
}

.about-product .product-data .id-availalbality span {
	display: block;
	min-width: 192px;
	font-size: 14px;
}

.about-product .product-data .id-availalbality span.availability {
	color: #00bbff;
}

.about-product .product-data .artist-color {
	display: flex;
	margin-top: 14px;
}

.about-product .product-data .artist-color {
	display: flex;
	margin-top: 14px;
}

.about-product .product-data .artist-color .artist {
	min-width: 192px;
}

.about-product .product-data .artist-color .artist span.name {
	cursor: pointer;
	color: var(--extra-color-2);
}

.about-product .product-data .artist-color .artist span {
	font-size: 14px;
}

.about-product .colors {
	display: flex;
	gap: 7px;
	align-items: center;
}

.about-product .colors span {
	font-size: 13px;
}

.about-product .product-data .colors .colors-list {
	display: flex;
	gap: 7px;
}

.about-product .colors .colors-list span.single-color {
	padding: 3px 12px;
	border-radius: 14px;
}

/* Custom Radio Button  */
.resolutionlists .single-resolution [type="radio"]:checked,
.resolutionlists .single-resolution [type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
}

.resolutionlists .single-resolution [type="radio"]:checked+label,
.resolutionlists .single-resolution [type="radio"]:not(:checked)+label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	color: #666;
}

.resolutionlists .single-resolution [type="radio"]:checked+label:before,
.resolutionlists .single-resolution [type="radio"]:not(:checked)+label:before {
	content: "";
	position: absolute;
	left: 0px;
	top: 4px;
	width: 18px;
	height: 18px;
	border: 1px solid var(--default-text-color);
	border-radius: 100%;
	background: transparent;
}

.resolutionlists .single-resolution [type="radio"]:checked+label:after,
.resolutionlists .single-resolution [type="radio"]:not(:checked)+label:after {
	content: "";
	width: 10px;
	height: 10px;
	background: var(--extra-color-2);
	position: absolute;
	top: 8px;
	left: 4px;
	border-radius: 100%;
	transition: all 0.2s ease;
}

.resolutionlists .single-resolution [type="radio"]:not(:checked)+label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.resolutionlists .single-resolution [type="radio"]:checked+label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

/* Resoukltion Boxes */
.resolutionlists {
	border: 1px solid #ffffff85;
	border-radius: 10px;
	padding: 10px 0px 20px;
	margin-top: 31px;
}

.resolutionlists .single-resolution {
	padding: 19px 30px;
}

.resolutionlists .single-resolution:not(:last-child) {
	border-bottom: 1px solid var(--light-dark-color);
}

.resolutionlists .single-resolution:nth-child(3) {
	border: 0px !important;
}

.resolutionlists .single-resolution label h6,
.resolutionlists .single-resolution label p {
	color: var(--default-text-color);
	margin-bottom: 0px;
}

.resolutionlists .single-resolution label p {
	font-family: var(--font-light);
}

/* Tags Start Here */
.about-product .tags {
	margin-top: 29px;
}

.about-product .tags .heading {
	display: block;
	margin-bottom: 12px;
	font-size: 14px;
	font-family: var(--font-default);
}

.tags .tags-flex {
	display: flex;
	flex-wrap: wrap;
	gap: 7px 14px;
}

.tags .tags-flex button.single_tag {
	background: #141737;
	color: var(--button-text-color);
	position: relative;
	padding: 4px 25px 8px;
	border: 1px solid #cecece;
}

.tags .tags-flex button.single_tag span.tag-name {
	font-size: 10px;
	font-family: var(--font-light);
}

.tags .tags-flex button.single_tag span.cross {
	font-size: 6px;
	font-family: var(--font-semibold);
	position: absolute;
	top: 3px;
	right: 10px;
	cursor: pointer;
}

.about-product .tags {
	margin-top: 29px;
}

.image-gallery .morelikethis {
	background: var(--extra-color-2);
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border: 0px;
	color: var(--default-text-color);
}

.image-gallery figure {
	margin-bottom: 0px;
	border-radius: 10px;
	overflow: hidden;
}

/* Product Detail Css End Here */

/* responsive starts here */
@media (max-width: 1366px) {
	.about-product .product-data .artist-color {
		flex-wrap: wrap;
	}
}

@media (max-width: 1280px) {
	.tags .tags-flex button.single_tag {
		padding: 4px 20px 8px;
	}

	.image-gallery .morelikethis span {
		font-size: 14px;
	}
}

@media (max-width: 480px) {
	.product-detail .video-sec .bottom-bar {
		flex-direction: column;
		align-items: baseline;
	}

	.product-detail .video-sec .bottom-bar .printest {
		padding-bottom: 19px;
	}

	.product-detail .details .single-data.right-side .question {
		width: 53%;
	}

	.product-detail .details .single-data .answer {
		width: 47%;
	}

	.product-detail .details .single-data {
		padding-bottom: 10px;
	}

	.about-product .product-data .id-availalbality {
		flex-direction: column;
	}

	.about-product .product-data .artist-color {
		flex-direction: column;
	}

	.product-detail .about-product {
		margin-top: 40px;
	}

	.about-product .product-data .id-availalbality span {
		display: block;
		min-width: 192px;
		margin-bottom: 10px;
		font-size: 14px;
	}

	.about-product .product-data .artist-color .artist {
		min-width: 100%;
		margin-bottom: 14px;
	}

	.about-product .top-heading span {
		margin-bottom: 14px;
		display: block;
	}

	.about-product .image-gallery img.gal-img {
		width: 100%;
	}

	.product-detail .about-product .pla-img-wrapper {
		top: 0%;
	}

	.product-detail .video-sec .bottom-bar .other-action {
		flex-wrap: wrap;
	}
}

/* Color Palatte Css Start Here */
.product-detail .about-product .color {
	display: flex;
	align-items: center;
}

.about-product .all-colors-palatte {
	display: flex;
	align-items: center;
}

.about-product .all-colors-palatte .label {
	flex: 0 0 20%;
	font-size: 13px;
}

.product-detail .about-product .color .colors-palatte {
	flex-wrap: wrap;
}

.product-detail .about-product .color .colors-palatte .palatte-box {
	height: 25px;
	padding: 3px 15px;
	border-radius: 14px;
	font-size: 12px;
	line-height: 20px;
	margin: 0 3px 5px;
}

/* Color Palatte Css End Here */