/* Cart Css Start Here */
.cart_sec {
	padding-top: 106px;
	padding-bottom: 150px;
	background-image: url("../img/login-bg.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top center;
}
.cart_sec .cart_warpper {
	border: 1px solid #ffffff4f;
	border-radius: 12px;
	padding: 70px 60px 50px;
	max-width: 95%;
	margin: 30px 0 0 auto;
}
.cart-box {
	border: 1px solid #ffffff69;
	border-radius: 13px;
	padding: 20px 20px 20px 50px;
	max-width: 90%;
	margin-bottom: 35px;
	position: relative;
}
.cart-box h5 {
	font-size: 16px;
	font-family: var(--font-light);
	line-height: 16px;
	font-weight: 500;
}
.cart-box h3 {
	font-size: 20px;
	line-height: 30px;
	margin: 0;
}
.cart-box h2 {
	color: var(--extra-color-2);
	font-size: 24px;
}
.cart-box p {
	font-size: 14px;
}
.cart-box button.cancel-btn {
	background: #00bbff;
	font-weight: 500;
	position: absolute;
	left: 0;
	top: 0px;
	width: 32px;
	border: 0;
	height: 22px;
	border-radius: 12px 0 10px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
}
.cart-box h3 {
	font-size: 20px;
	line-height: 30px;
	margin: 0;
}
.cart-box p {
	font-size: 14px;
}
.cart-box .button-group .btn {
	background-image: linear-gradient(
		to bottom,
		#0b1140,
		#131847,
		#1b204d,
		#222754,
		#292f5b
	);
	border: 1px solid #ffffff69;
	border-radius: 4px;
}
.cart-box button.cancel-btn {
	background: #00bbff;
	font-weight: 500;
	position: absolute;
	left: 0;
	top: 0px;
	width: 32px;
	border: 0;
	height: 22px;
	border-radius: 12px 0 10px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
}
.cart_sec .total-wrapper {
	border: 1px solid #ffffff69;
	padding: 50px 20px 35px;
	width: 35%;
	margin: 0 0 0 auto;
	border-radius: 12px;
	margin-top: 30px;
}
.total-wrapper .item-total h5 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 13px;
	text-transform: uppercase;
	font-size: 17px;
}
.total-wrapper .item-total {
	padding-bottom: 25px;
	border-bottom: 1px solid #c5c5c5;
	margin-bottom: 20px;
}
.total-wrapper .bottom-bar h5 {
	margin-bottom: 20px;
}
.total-wrapper .bottom-bar h5 {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.total-wrapper h5 span.value {
	font-family: var(--font-light);
}
.total-wrapper .bottom-bar span.property {
	color: #00bbff;
	text-transform: uppercase;
}
.total-wrapper .bottom-bar .button-group {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.total-wrapper .bottom-bar .button-group .btn {
	width: 43%;
}
/* cart 2 css starts here */
.cart_sec .main-cart-box {
	padding: 0px;
	display: flex;
	border: 0px;
	max-width: 99%;
}
.cart_sec .main-cart-box .cart-img-wrap {
	flex: 0 0 53%;
}
.cart_sec .main-cart-box .cart-main-content-wrap {
	flex: 0 0 47%;
}
.cart_sec .main-cart-box .cart-img-wrap img {
	height: 184px;
	object-fit: cover;
	border-radius: 13px;
}
.cart_sec .main-cart-box .cart-main-content-wrap h3 {
	font-size: 16px;
}
.main-cart-box .cart-main-content-wrap .button-group a.package.btn {
	padding: 7px 10px;
	font-size: 14px;
	margin-top: 0px;
}
.cart2 .testimonials-wrapper p {
	font-size: 20px;
	color: var(--default-text-color);
	font-family: var(--font-italic);
}
.cart_sec .main-cart-box .cart-main-content-wrap {
	flex: 0 0 40%;
	margin-left: 19px;
}
/* cart 2 css ends here */
@media (max-width: 1400px) {
	.cart_sec .total-wrapper {
		width: 45%;
	}
}
@media (max-width: 1366px) {
	.cart_sec .main-cart-box .cart-img-wrap {
		flex: 0 0 49%;
	}
	.cart_sec .main-cart-box .cart-main-content-wrap {
		flex: 0 0 46%;
	}
}
@media (max-width: 1100px) {
	.cart_sec .cart_warpper {
		padding: 50px 30px 30px;
		max-width: 100%;
	}
	.cart-box {
		max-width: 100%;
	}
}
@media (max-width: 991px) {
	.cart_sec {
		padding-bottom: 80px;
	}
	.cart_sec .cart_warpper {
		padding: 30px 10px 30px;
		max-width: 100%;
	}
	.cart_sec .total-wrapper {
		width: 100%;
	}
	.cart-box {
		padding: 40px 20px 20px 20px;
	}
}
@media (max-width: 425px) {
	.cart_sec .main-cart-box {
		max-width: 100%;
		flex-direction: column;
	}
	.cart_sec .main-cart-box .cart-main-content-wrap {
		flex: 0 0 50%;
		margin: 0px;
		margin-top: 27px;
	}
}
@media (max-width: 414px) {
	.cart_sec {
		padding-bottom: 50px;
	}
	.total-wrapper .bottom-bar .button-group {
		flex-direction: column-reverse;
	}
	.total-wrapper .item-total h5 {
		font-size: 15px;
	}
	.total-wrapper .bottom-bar span.property {
		font-size: 15px;
	}
	.total-wrapper h5 span.value {
		font-size: 15px;
	}
	.total-wrapper .bottom-bar .button-group .btn {
		width: 100%;
	}
}
/* Cart Css End Here */
