.loader-container {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	background: var(--extra-color-1);
	z-index: 1;
}

.spinner {
	width: 64px;
	height: 64px;
	border: 8px solid;
	border-color: var(--extra-color-2) transparent var(--extra-color-2)
		transparent;
	border-radius: 50%;
	animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* Global css starts here */
body {
	background-color: var(--body-color);
	color: var(--default-text-color);
}

a,
a:hover {
	color: var(--default-text-color);
	text-decoration: none;
}

.btn {
	background: var(--extra-color-2);
	border-radius: 4px;
	color: var(--default-text-color);
	font-family: var(--font-default);
	padding: 10px 29px;
	margin-top: 11px;
	border: 1px solid transparent;
}

.btn:hover {
	background: var(--extra-color-3) !important;
}

@media (max-width: 1200px) {
	.btn {
		padding: 10px 12px;
	}
}

.socket-wrapper {
	background: var(--extra-color-1);
	width: 100%;
	height: 100vh;
}

.socket {
	width: 200px;
	height: 200px;
	position: absolute;
	left: 50%;
	margin-left: -100px;
	top: 50%;
	margin-top: -100px;
}

.hex-brick {
	background: var(--extra-color-2);
	width: 30px;
	height: 17px;
	position: absolute;
	top: 5px;
	animation-name: fade;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	-webkit-animation-name: fade;
	-webkit-animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
}

.h2 {
	transform: rotate(60deg);
	-webkit-transform: rotate(60deg);
}

.h3 {
	transform: rotate(-60deg);
	-webkit-transform: rotate(-60deg);
}

.gel {
	height: 30px;
	width: 30px;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
	position: absolute;
	top: 50%;
	left: 50%;
}

.center-gel {
	margin-left: -15px;
	margin-top: -15px;

	animation-name: pulse;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	-webkit-animation-name: pulse;
	-webkit-animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
}

.c1 {
	margin-left: -47px;
	margin-top: -15px;
}

.c2 {
	margin-left: -31px;
	margin-top: -43px;
}

.c3 {
	margin-left: 1px;
	margin-top: -43px;
}

.c4 {
	margin-left: 17px;
	margin-top: -15px;
}

.c5 {
	margin-left: -31px;
	margin-top: 13px;
}

.c6 {
	margin-left: 1px;
	margin-top: 13px;
}

.c7 {
	margin-left: -63px;
	margin-top: -43px;
}

.c8 {
	margin-left: 33px;
	margin-top: -43px;
}

.c9 {
	margin-left: -15px;
	margin-top: 41px;
}

.c10 {
	margin-left: -63px;
	margin-top: 13px;
}

.c11 {
	margin-left: 33px;
	margin-top: 13px;
}

.c12 {
	margin-left: -15px;
	margin-top: -71px;
}

.c13 {
	margin-left: -47px;
	margin-top: -71px;
}

.c14 {
	margin-left: 17px;
	margin-top: -71px;
}

.c15 {
	margin-left: -47px;
	margin-top: 41px;
}

.c16 {
	margin-left: 17px;
	margin-top: 41px;
}

.c17 {
	margin-left: -79px;
	margin-top: -15px;
}

.c18 {
	margin-left: 49px;
	margin-top: -15px;
}

.c19 {
	margin-left: -63px;
	margin-top: -99px;
}

.c20 {
	margin-left: 33px;
	margin-top: -99px;
}

.c21 {
	margin-left: 1px;
	margin-top: -99px;
}

.c22 {
	margin-left: -31px;
	margin-top: -99px;
}

.c23 {
	margin-left: -63px;
	margin-top: 69px;
}

.c24 {
	margin-left: 33px;
	margin-top: 69px;
}

.c25 {
	margin-left: 1px;
	margin-top: 69px;
}

.c26 {
	margin-left: -31px;
	margin-top: 69px;
}

.c27 {
	margin-left: -79px;
	margin-top: -15px;
}

.c28 {
	margin-left: -95px;
	margin-top: -43px;
}

.c29 {
	margin-left: -95px;
	margin-top: 13px;
}

.c30 {
	margin-left: 49px;
	margin-top: 41px;
}

.c31 {
	margin-left: -79px;
	margin-top: -71px;
}

.c32 {
	margin-left: -111px;
	margin-top: -15px;
}

.c33 {
	margin-left: 65px;
	margin-top: -43px;
}

.c34 {
	margin-left: 65px;
	margin-top: 13px;
}

.c35 {
	margin-left: -79px;
	margin-top: 41px;
}

.c36 {
	margin-left: 49px;
	margin-top: -71px;
}

.c37 {
	margin-left: 81px;
	margin-top: -15px;
}

.r1 {
	animation-name: pulse;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-delay: 0.2s;
	-webkit-animation-name: pulse;
	-webkit-animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-delay: 0.2s;
}

.r2 {
	animation-name: pulse;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-delay: 0.4s;
	-webkit-animation-name: pulse;
	-webkit-animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-delay: 0.4s;
}

.r3 {
	animation-name: pulse;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-delay: 0.6s;
	-webkit-animation-name: pulse;
	-webkit-animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-delay: 0.6s;
}

.r1 > .hex-brick {
	animation-name: fade;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-delay: 0.2s;
	-webkit-animation-name: fade;
	-webkit-animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-delay: 0.2s;
}

.r2 > .hex-brick {
	animation-name: fade;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-delay: 0.4s;
	-webkit-animation-name: fade;
	-webkit-animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-delay: 0.4s;
}

.r3 > .hex-brick {
	animation-name: fade;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-delay: 0.6s;
	-webkit-animation-name: fade;
	-webkit-animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-delay: 0.6s;
}

@keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	50% {
		-webkit-transform: scale(0.01);
		transform: scale(0.01);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes fade {
	0% {
		background: var(--extra-color-2);
	}

	50% {
		background: var(--extra-color-2);
	}

	100% {
		background: var(--extra-color-2);
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	50% {
		-webkit-transform: scale(0.01);
		transform: scale(0.01);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@-webkit-keyframes fade {
	0% {
		background: var(--extra-color-2);
	}

	50% {
		background: var(--extra-color-2);
	}

	100% {
		background: var(--extra-color-2);
	}
}

/* Global css ends here */
/* Typography starts here */
h1 {
	font-family: var(--font-heading);
	font-weight: 700;
	font-size: 60px;
	line-height: 1.5;
	color: var(--font-heading-color);
}

h2 {
	font-family: var(--font-heading);
	font-weight: 700;
	font-size: 40px;
	line-height: 1.5;
	color: var(--font-heading-color);
}

h3 {
	font-family: var(--font-heading);
	font-weight: 700;
	font-size: 36px;
	line-height: 1.5;
	color: var(--font-heading-color);
}

h4 {
	font-family: var(--font-heading);
	font-weight: 700;
	font-size: 30px;
	line-height: 1.5;
	color: var(--font-heading-color);
}

h5 {
	font-family: var(--font-heading);
	font-weight: 700;
	font-size: 23px;
	line-height: 1.5;
	color: var(--font-heading-color);
}

h6 {
	font-family: var(--font-heading);
	font-weight: 700;
	font-size: 20px;
	line-height: 1.5;
	color: var(--font-heading-color);
}

p {
	font-family: var(--font-light);
	font-size: 13px;
	line-height: 1.5;
	color: var(--text-color);
}

@media (max-width: 1200px) {
	h1 {
		font-size: 50px;
		line-height: 1.2;
	}

	h2 {
		font-size: 30px;
		line-height: 1.2;
	}
}

@media (max-width: 991px) {
	h1 {
		font-size: 35px;
		line-height: 1.5;
	}
}

@media (max-width: 575px) {
	h1 {
		font-size: 25px;
		line-height: 1.5;
	}

	h2 {
		font-size: 22px;
		line-height: 1;
	}

	h3 {
		font-size: 25px;
		line-height: 1;
	}

	h4 {
		font-size: 23px;
		line-height: 1.3;
	}

	h5 {
		font-size: 20px;
		line-height: 1.3;
	}
}

/* Typography ends here */

/* Search Filter Css Start Here */
.search-filter {
	display: none;
}

.search-filter.show {
	display: block;
}

header#top .search-filter .search-box {
	display: flex;
	background: #fff;
	border-radius: 10px;
	position: absolute;
	width: 50%;
	right: 26.5%;
	top: 45px;
	transition: 0.6s;
}

header#top .search-filter .search-box .form-group {
	flex: 1;
	display: flex;
	position: relative;
}

header#top .search-filter .search-box button.camera {
	position: absolute;
	right: 10px;
	top: 13px;
	background: transparent;
	border: 0;
	box-shadow: none;
	padding: 0 10px;
}

header#top .search-filter .search-box .form-control {
	padding: 12px 63px 12px 20px;
	font-family: "Vietnam-light";
	outline: none;
	border: 0;
	box-shadow: none;
}

header#top .search-filter .search-box button.filter {
	background: transparent;
	border: 0;
	padding: 0 15px;
}

header#top .search-filter .search-box button.search {
	padding: 15px 20px;
	background: #004c66;
	border: 0;
	box-shadow: none;
	height: 100%;
	border-radius: 0px 7px 7px 0px;
}

header#top .search-filter .search-box button.search:hover {
	background: #004c66b3;
	transition: all 0.4s ease-in-out;
}

@media (max-width: 1440px) {
	header#top .search-filter .search-box {
		right: 24%;
	}
}

@media (max-width: 1280px) {
	header#top .search-filter .search-box {
		right: 24.5%;
	}
}

@media (max-width: 1100px) {
	header#top .search-filter .search-box {
		width: 50%;
		right: 25%;
		top: 25px;
	}
}

@media (max-width: 1024px) {
	header#top .header-icons-wrapper ul.icon-menu li.icon-item button.icon-link {
		padding: 0px;
	}
}

@media (max-width: 991px) {
	header#top .search-filter .search-box {
		width: 50%;
		right: 31.5%;
		top: 45px;
	}
}

@media (max-width: 768px) {
	header#top .search-filter .search-box {
		width: 95%;
		right: 0;
		top: 35px;
		left: 0;
		height: 70px;
		margin: 0px auto;
	}

	header#top .search-filter .search-box button.camera {
		top: 20px;
	}
}

@media (max-width: 575px) {
	header#top .search-filter .search-box {
		top: 5px;
	}
}

/* Search Filter End Here */

/* Border Line Hover Css Start Here */
.border-line-left figure:before {
	content: "";
	background: transparent;
	width: 100%;
	height: 100%;
	position: absolute;
	left: -20px;
	z-index: -1;
	top: 15px;
	border: 1px solid #fff;
	transition: 0.6s;
}

.border-line-left figure {
	position: relative;
}

.border-line-left:hover figure:before {
	left: 12px;
	top: 30px;
}

.border-line-right figure:before {
	content: "";
	background: transparent;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 25px;
	z-index: -1;
	top: 30px;
	border: 1px solid #fff;
	transition: 0.6s;
}

.border-line-right figure {
	position: relative;
}

.border-line-right:hover figure:before {
	left: -15px;
	top: -20px;
}

/* Border Line Hover Css End Here */

/* Filter Css Start Here */
.flter-box {
	background: #004c66;
	position: absolute;
	z-index: 9;
	width: 46.5%;
	left: 23%;
	top: 35.4%;
	border-radius: 6px;
	padding-bottom: 35px;
	display: none;
}

.flter-box.show {
	display: block;
}

.flter-box .right-side {
	padding-right: 30px;
}

.flter-box .topbar {
	display: flex;
	align-items: center;
	padding: 15px 0;
}

.flter-box .topbar h4 {
	margin: 0;
	padding: 0 0 0 20px;
	font-size: 16px;
	font-family: "Vietnam-light";
	font-weight: 500;
}

.flter-box .topbar button {
	margin: 0;
	background: transparent;
	border: 0;
	padding: 10px 15px 10px 25px;
	box-shadow: none;
	outline: none;
	border-right: 1px solid #00000063;
	border-radius: 0;
}

.flter-box .radio-box {
	padding-top: 20px;
}

.flter-box .radio-box .head h3 {
	font-size: 20px;
	font-family: "Handel-Gothic";
}

.flter-box .radio-box ul.list {
	padding: 0 0;
	list-style: none;
}

.flter-box .radio-box ul.list li label span.checkmark h4 {
	font-size: 16px;
	font-family: "Vietnam-light";
	font-weight: 500;
	margin-bottom: 0;
}

.flter-box .radio-box ul.list li {
	border-top: 1px solid #ffffff38;
	padding: 10px 50px;
	position: relative;
}

.flter-box .radio-box ul.list li label span.checkmark .dot {
	width: 12px;
	height: 12px;
	position: absolute;
	border: 2px solid transparent;
	left: 22px;
	top: 16px;
	border-radius: 50%;
	background: transparent;
	outline: 1px solid #fff;
}

.flter-box .radio-box ul.list li label input:checked ~ span.checkmark .dot {
	background: #00bbff;
	border: 2px solid #004c66;
}

.flter-box .radio-box.colors .head {
	border-bottom: 1px solid #ffffff38;
}

.flter-box .radio-box.colors ul.list li label span.checkmark .dot {
	border: 3px solid #141737;
	outline: 0px solid #fff;
}

.flter-box
	.radio-box.colors
	ul.list
	li
	label
	input:checked
	~ span.checkmark
	.dot {
	outline: 1px solid #fff;
}

.flter-box .radio-box.colors label span.checkmark .dot {
	width: 26px !important;
	height: 26px !important;
}

.flter-box .radio-box.colors li {
	padding: 15px 20px !important;
}

header .flter-box .radio-box .head h3 {
	font-size: 20px;
	font-family: "Handel-Gothic";
	padding: 0 0 0 25px;
}

.flter-box .radio-box.colors li {
	border: 0 !important;
}

.flter-box .radio-box.colors ul.list {
	border-bottom: 1px solid #ffffff38;
	display: flex;
}

.flter-box .radio-box.colors ul.list li {
	padding: 0 15px 20px;
}

.flter-box .radio-box.tags .head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #ffffff38;
	margin-bottom: 15px;
}

.flter-box .radio-box.tags .head h3 {
	padding-left: 0;
}

.flter-box .radio-box.tags .head button.clear {
	color: #00bbff;
	background: transparent;
	border: 0;
	padding: 0;
	font-family: "Vietnam-reg";
}

.flter-box .radio-box.tags ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.flter-box .radio-box.tags ul li {
	display: inline-block;
}

.flter-box .radio-box.tags ul li button {
	background: #05092c6b;
	outline: none;
	box-shadow: none;
	color: #fff;
	font-size: 12px;
	font-family: "Vietnam-reg";
	position: relative;
	padding: 10px 20px;
	margin: 10px 10px 0 0;
	border: 1px solid #cecece69;
}

.flter-box .radio-box.tags ul li button span.cut {
	position: absolute;
	right: 5px;
	top: 1px;
}

.flter-box .images-box .head h3 {
	padding: 0;
}

.flter-box .images-box ul.list li {
	padding-left: 25px;
}

.flter-box .images-box ul.list li span.checkmark .dot {
	left: 0 !important;
	top: 17px !important;
}

.flter-box .radio-box.color-picker .head {
	border-bottom: 1px solid #ffffff38;
	margin-bottom: 15px;
}

.flter-box .radio-box.color-picker .head h3 {
	padding-left: 0;
}

.flter-box .radio-box.color-picker .clip-field {
	position: relative;
}

.flter-box .radio-box.color-picker .clip-field .form-control {
	background: #022a48;
	border: 1px solid #ffffff82;
	border-radius: 0;
	padding: 14px 10px 14px 55px;
	font-family: "Vietnam-light";
	font-size: 12px;
	color: #fff;
	border-radius: 5px;
}

.flter-box .radio-box.color-picker .clip-field .form-control::placeholder {
	color: #fff;
}

.flter-box .radio-box.color-picker .clip-field button {
	position: absolute;
	left: 10px;
	top: 12px;
	height: 25px;
	width: 25px;
	border: 0;
	border-radius: 50%;
}

.flter-box .radio-box.color-picker .twitter-picker {
	display: none !important;
}

.flter-box .radio-box.color-picker .twitter-picker.picker-show {
	display: block !important;
}

@media (max-width: 1440px) {
	.flter-box {
		width: 48.5%;
		left: 23%;
		top: 41.4%;
		height: 600px;
		overflow-y: scroll;
		overflow-x: hidden;
	}
}

@media (max-width: 1366px) {
	.flter-box {
		top: 46.4%;
	}
}

@media (max-width: 1280px) {
	.flter-box {
		top: 46.4%;
		left: 25.5%;
		width: 45.5%;
	}

	header#top .search-filter .search-box .form-control {
		padding: 8px 63px 8px 20px;
	}

	header#top .search-filter .search-box button.search {
		padding: 13px 20px;
	}
}

@media (max-width: 991px) {
	.flter-box {
		width: 50%;
		left: 18.5%;
		top: 33.4%;
	}

	.flter-box .radio-box {
		padding: 20px 20px;
	}

	.flter-box .radio-box ul.list li label span.checkmark .dot {
		left: 0px !important;
	}

	.flter-box .radio-box ul.list li {
		padding: 10px 25px;
	}

	header .flter-box .radio-box .head h3 {
		padding: 0 0 0 0px;
	}
}

@media (max-width: 768px) {
	.flter-box {
		width: 95%;
		left: 0;
		top: 24px;
		right: 0;
		margin: 0px auto;
	}
}

@media (max-width: 575px) {
	.flter-box {
		top: 4px;
	}
}

/* Filter Css End Here */

/* Header css starts here */
header.inner-page {
	background: #05092c !important;
}

header#top {
	background: url("../img/header-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	position: fixed;
	padding-top: 19px;
	top: 0%;
	z-index: 12;
	width: 100%;
	background-color: var(--body-color);
}

header#top .header-icons-wrapper ul.icon-menu {
	padding: 0px;
	list-style: none;
	display: flex;
	margin: 0px;
	align-items: center;
	justify-content: space-between;
}

header#top .header-icons-wrapper ul.icon-menu li.icon-item button {
	border: 0;
	background: transparent;
	color: #fff;
	outline: none;
	box-shadow: none;
}

header#top .header-icons-wrapper .welcome-sec span {
	display: block;
}

header#top a {
	font-family: var(--font-default);
}

header .header-icons-wrapper .icon-menu .wel-wrapper {
	display: flex;
}

header#top .header-icons-wrapper ul.icon-menu .icon-item i {
	font-size: 19px;
}

header .header-icons-wrapper .icon-menu .wel-wrapper .welcome-sec {
	text-align: end;
	margin-right: 13px;
}

header
	.header-icons-wrapper
	.icon-menu
	.wel-wrapper
	.welcome-sec
	span.greeting {
	font-size: 10px;
	font-weight: 400;
}

header .header-icons-wrapper .icon-menu .wel-wrapper .welcome-sec span.name {
	font-size: 14px;
	font-weight: 500;
}

header#top .header-icons-wrapper ul.icon-menu .profile-icon a::after {
	display: none;
}

header#top .header-menu-wrapper a {
	text-transform: uppercase;
	font-size: 14px;
	box-shadow: inset 0 0 0 0 #00bbff;
	color: #fff;
	font-weight: 700;
	transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
	height: 19px;
	overflow: hidden;
	display: block;
	position: relative;
	padding: 0px;
}
header#top .header-menu-wrapper a span {
	display: block;
	width: 100%;
	height: 100%;
	transition: 0.4s;
}
header#top .header-menu-wrapper a span:nth-child(1) {
	color: #fff;
}
header#top .header-menu-wrapper a span:nth-child(2) {
	color: #fff;
}
header#top .header-menu-wrapper a:hover span:nth-child(1) {
	transform: translateY(-100%);
}
header#top .header-menu-wrapper a:hover span:nth-child(2) {
	transform: translateY(-100%);
}
header#top .header-menu-wrapper a.nav-link span {
	font-size: 14px;
	font-weight: 600;
	font-family: "Vietnam-reg";
	transition: all 0.4s ease-in-out;
	text-transform: uppercase;
}

/* header#top .header-menu-wrapper a.nav-link span:hover {
	transition: all 0.4s ease-in-out;
	font-size: 15px;
	text-shadow: 1px 1px 1px var(--extra-color-2),
		3px 3px 5px var(--extra-color-2);
} */

header#top
	.header-icons-wrapper
	ul.icon-menu
	.icon-item
	i[class="fa-regular fa-heart"]:hover {
	color: red;
}

header#top
	.header-icons-wrapper
	ul.icon-menu
	.icon-item
	i[class="fa-regular fa-heart"]:hover:before {
	content: "\f004";
	font-family: "FontAwesome";
	transition: 0.4s;
}

header#top .header-menu-wrapper a.active span {
	color: var(--extra-color-2);
	font-weight: 700;
}

header#top
	.header-icons-wrapper
	ul.icon-menu
	.icon-item
	i[class="fa-solid fa-magnifying-glass"]:hover {
	font-size: 23px;
	transition: 0.4s;
}

/* header#top .header-menu-wrapper a:hover {
	box-shadow: inset 140px 0 0 0 #00bbff;
	color: white;
} */
header#top .icon-menu li.nav-item ul.dropdown-menu {
	background: var(--extra-color-1);
	border-radius: 0px;
	padding: 0px;
}

header#top .icon-menu li.nav-item ul.dropdown-menu a {
	color: var(--default-text-color);
	border-bottom: 1px solid var(--default-text-color);
	padding: 9px 10px;
}

header#top .icon-menu li.nav-item ul.dropdown-menu li:last-child a {
	border-bottom: 0px;
}

header#top .icon-menu li.nav-item ul.dropdown-menu a:hover {
	background: var(--extra-color-2);
	color: var(--default-text-color);
}

header#top .header-icons-wrapper .responsive-bar button {
	display: none;
}

header#top .header-icons-wrapper ul.icon-menu li.icon-item button.search {
	width: 30px;
	height: 60px;
}

header#top .header-icons-wrapper ul.icon-menu li.icon-item button.search:hover {
	color: var(--extra-color-2);
}

@media (max-width: 1440px) {
	header#top .header-menu-wrapper a {
		font-size: 11px;
	}

	header#top .header-icons-wrapper ul.icon-menu .icon-item i {
		font-size: 14px;
		margin: 0 5px;
	}

	header .header-icons-wrapper .icon-menu .wel-wrapper {
		margin-left: 12px;
	}
}

@media (max-width: 1100px) {
	header#top .header-icons-wrapper ul.icon-menu .icon-item i {
		margin: 0 2px;
	}
}

@media (max-width: 991px) {
	header#top .header-menu-wrapper {
		display: none;
	}

	header#top .header-icons-wrapper {
		display: flex;
		justify-content: end;
		align-items: center;
	}

	header#top .header-icons-wrapper .responsive-bar {
		padding-left: 15px;
	}

	header#top .header-icons-wrapper .responsive-bar button {
		background: transparent;
		border: 0;
		outline: none;
		box-shadow: none;
		color: #fff;
		padding: 0;
		font-size: 22px;
	}

	header#top .for-responsive {
		display: none;
	}

	header#top .header-icons-wrapper ul.icon-menu .icon-item i {
		font-size: 20px;
	}

	header#top .header-icons-wrapper .responsive-bar button {
		display: block;
	}
}

/* Header css ends here */

/* Mobile Header Start Here */
.mobile-header {
	background: #091f36;
	position: fixed;
	right: 0;
	top: 0;
	width: 30%;
	height: 100%;
	z-index: 13;
	display: flex;
	align-items: center;
	padding: 30px 30px;
	transform: translateX(150%);
	transition: 0.7s;
}

.mobile-header.active {
	transform: translateX(0%);
}

.mobile-header ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.mobile-header .cancel {
	position: absolute;
	top: 20px;
	right: 20px;
}

.mobile-header .cancel button {
	background: transparent;
	color: #fff;
	font-size: 25px;
	border: 0;
	padding: 0;
}

.mobile-header ul li {
	margin-bottom: 10px;
}

.mobile-header ul li a {
	font-family: "Vietnam-light";
}

.mobile-header li {
	list-style: none;
}

.mobile-header .language-tarnslator {
	margin-top: 20px;
}

.mobile-header .action-btns {
	margin-top: 20px;
}

.mobile-header .action-btns ul {
	display: flex;
}

.mobile-header .action-btns ul li {
	margin-right: 15px;
}

@media (max-width: 575px) {
	.mobile-header {
		width: 50%;
	}
}

@media (max-width: 414px) {
	.mobile-header {
		width: 65%;
	}
}

/* Mobile Header End Here */

/* banner starts here */
.banner-slide-wrapper {
	/* background-image: url("../img/banner-bg.png"); */
	padding: 194px 0px 100px 0px;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	top: 0;
	width: 100%;
}

.banner-sec img.banner-bg-img {
	height: 106vh;
}

.banner-sec .banner-explore-wrapper {
	position: relative;
}

.banner-sec .banner-explore-wrapper .banner-explore-content-wrapper h6 {
	margin: 0px;
}

.banner-sec .banner-explore-wrapper .banner-explore-content-wrapper h5 {
	font-size: 25px;
	line-height: 1;
	text-transform: uppercase;
}

.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper1 {
	height: 216px;
	width: 251px;
	text-align: center;
	background: url("../img/banner-cat.png");
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: contain;
	background-position: center center;
	transition: all 0.4s ease-in-out;
	position: relative;
}

.banner-sec
	.banner-explore-wrapper
	.banner-explore-card-wrapper1:hover
	.banner-explore-content-wrapper
	h5,
.banner-sec
	.banner-explore-wrapper
	.banner-explore-card-wrapper1:hover
	.banner-explore-content-wrapper
	h6 {
	color: var(--extra-color-2);
}

/* .banner-sec .banner-explore-wrapper .banner-explore-card-wrapper1:hover {
  transform: scale(1.1);
  z-index: 9;
}

.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper2:hover {
  transform: scale(1.1);
  z-index: 9;
}

.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper3:hover {
  transform: scale(1.1);
  z-index: 9;
} */

.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper2 {
	height: 216px;
	width: 251px;
	text-align: center;
	background: url("../img/banner-cat2.png");
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: contain;
	background-position: center center;
	transition: all 0.4s ease-in-out;
}

.banner-sec
	.banner-explore-wrapper
	.banner-explore-card-wrapper2:hover
	.banner-explore-content-wrapper
	h5,
.banner-sec
	.banner-explore-wrapper
	.banner-explore-card-wrapper2:hover
	.banner-explore-content-wrapper
	h6 {
	color: var(--extra-color-2);
}

.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper3 {
	height: 216px;
	width: 251px;
	text-align: center;
	background: url("../img/banner-cat3.png");
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: contain;
	background-position: center center;
	transition: all 0.4s ease-in-out;
}

/* .banner-sec .banner-explore-wrapper .banner-explore-card-wrapper1::after {
    content: '';
    height: 216px;
    width: 247px;
    position: absolute;
    background: url('../img/Group 127.png');
    display: block;
    background-size: cover;
} */

.banner-sec
	.banner-explore-wrapper
	.banner-explore-card-wrapper3:hover
	.banner-explore-content-wrapper
	h5,
.banner-sec
	.banner-explore-wrapper
	.banner-explore-card-wrapper3:hover
	.banner-explore-content-wrapper
	h6 {
	color: var(--extra-color-2);
}

.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper2 {
	position: absolute;
	top: 45%;
	left: 26.5%;
}

.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper3 {
	position: absolute;
	top: 0%;
	right: 7%;
}

.banner-slide-wrapper .low-bane {
	padding-top: 74px;
	position: relative;
	top: 74px;
}

.banner-slide-wrapper a.more {
	height: 179px;
	width: 207px;
	background-image: url("../img/btn-bg.png");
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: contain;
	flex-direction: column;
}

.banner-slide-wrapper a.more:hover {
	color: var(--extra-color-2);
	transition: all 0.4s ease-in-out;
	/* transform: scale(1.1); */
}

.banner-slide-wrapper a.more span {
	font-size: 25px;
	font-family: var(--font-heading);
	text-transform: uppercase;
}

.banner-slide-wrapper .banner-ends {
	padding-top: 122px;
}

.banner-sec ul.slick-dots {
	transform: rotate(90deg);
	position: absolute;
	right: -38%;
	bottom: 57%;
	z-index: 3;
}

.banner-sec {
	position: relative;
	overflow: hidden;
}

.banner-sec ul.slick-dots li button::before {
	content: "";
	border: 2px solid #fff;
	border-radius: 50%;
	height: 10px;
	width: 10px;
	display: block;
	padding: 0px;
	opacity: 1;
}

.banner-sec ul.slick-dots li {
	margin: 0px;
}

.register-banner-btn-wrapper {
	position: absolute;
	top: 23%;
	right: 0%;
	background: #ffffff33;
	padding: 16px 16px 16px 29px;
	clip-path: polygon(6% 0, 100% 0%, 100% 100%, 8% 99%, 0 50%);
	transition: all 0.4s ease-in-out;
}

.register-banner-btn-wrapper:hover {
	transition: all 0.5s ease-in-out;
	box-shadow: 216px 0px 0px 0px inset var(--extra-color-1);
}

.register-banner-btn-wrapper a span {
	display: block;
}

.register-banner-btn-wrapper span.big {
	font-size: 27px;
	text-transform: capitalize;
	font-weight: 700;
	line-height: 1;
}

.register-banner-btn-wrapper span {
	font-size: 16px;
}

.banner-sec ul.slick-dots li.slick-active button::before {
	background: white;
}

@media (max-width: 1600px) {
	.banner-sec img.banner-bg-img {
		height: 879px;
	}
}

@media (max-width: 1440px) {
	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper2 {
		top: 46%;
		left: 26.5%;
	}

	.banner-sec
		.banner-explore-wrapper
		.banner-explore-card-wrapper2
		.banner-explore-content-wrapper {
		margin-top: -13px;
	}

	.banner-sec img.banner-bg-img {
		height: 874px;
		object-fit: cover;
		object-position: center;
	}
}

@media (max-width: 1400px) {
	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper2 {
		top: 45.4%;
		left: 30.8%;
	}

	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper3 {
		top: 1%;
		right: -8%;
	}
}

@media (max-width: 1366px) {
	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper3 {
		right: -8%;
	}

	.banner-sec .banner-slide-wrapper .banner-content-wrapper h1 {
		font-size: 50px;
	}

	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper2 {
		top: 45%;
		left: 31%;
	}

	.banner-sec img.banner-bg-img {
		height: 835px;
		object-fit: cover;
		object-position: center;
	}
}

@media (max-width: 1200px) {
	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper2 {
		height: 183px;
		width: 200px;
	}

	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper1 {
		height: 215px;
		width: 200px;
	}

	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper3 {
		height: 210px;
		width: 200px;
	}

	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper3 {
		right: -5.5%;
	}

	.banner-slide-wrapper a.more {
		height: 170px;
		width: 200px;
		background-repeat: no-repeat;
	}
}

@media (max-width: 1024px) {
	.banner-slide-wrapper a.more {
		height: 170px;
		width: 170px;
		background-repeat: no-repeat;
	}

	.banner-sec img.banner-bg-img {
		height: 887px;
		object-fit: cover;
		object-position: center;
	}
}

@media (max-width: 991px) {
	.banner-slide-wrapper {
		padding: 40px 0px;
		text-align: center;
	}

	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper2 {
		position: unset;
	}

	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper3 {
		position: unset;
	}

	.banner-sec .banner-explore-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.banner-sec .banner-ends {
		padding-top: 20px;
	}

	.banner-slide-wrapper .low-bane {
		position: unset;
		padding: 0;
	}

	.banner-slide-wrapper .low-bane .banner-lower-content-wrapper {
		padding-bottom: 40px;
	}

	.banner-slide-wrapper .low-bane .more {
		margin: 0px auto;
	}
}

@media (max-width: 768px) {
	.banner-sec .banner-explore-wrapper {
		padding-bottom: 10px;
		display: block;
		justify-content: center;
		align-items: center;
	}

	.banner-sec img.banner-bg-img {
		height: 1234px;
		object-fit: cover;
		object-position: center;
	}

	.register-banner-btn-wrapper {
		top: 25%;
	}

	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper1 {
		height: 175px;
		margin: 0px auto;
	}

	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper2 {
		height: 175px;
		margin: 0px auto;
	}

	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper3 {
		height: 175px;
		margin: 0px auto;
	}

	.banner-slide-wrapper .low-bane .banner-lower-content-wrapper {
		padding-bottom: 7px;
	}

	.banner-slide-wrapper {
		padding: 141px 0px 18px 0px;
	}
}

@media (max-width: 480px) {
	.banner-sec .banner-slide-wrapper .banner-content-wrapper h1 {
		font-size: 30px;
	}

	.banner-sec img.banner-bg-img {
		height: 1109px;
	}

	.banner-slide-wrapper {
		padding: 118px 0px 18px 0px;
	}

	.banner-slide-wrapper .low-bane .banner-lower-content-wrapper {
		padding-bottom: 0px;
	}

	.register-banner-btn-wrapper span {
		font-size: 13px;
	}

	.register-banner-btn-wrapper span.big {
		font-size: 21px;
	}

	.register-banner-btn-wrapper {
		top: 18%;
		padding: 12px 7px 12px 29px;
	}

	.banner-sec .banner-explore-wrapper .banner-explore-content-wrapper h5 {
		font-size: 18px;
	}

	.banner-slide-wrapper a.more span {
		font-size: 19px;
	}

	.banner-slide-wrapper a.more {
		height: 152px;
		width: 165px;
	}
}

@media (max-width: 420px) {
	.banner-sec img.banner-bg-img {
		height: 1110px;
	}

	.register-banner-btn-wrapper {
		top: 20%;
		padding: 12px 7px 12px 29px;
	}
}

@media (max-width: 395px) {
	.banner-sec .banner-slide-wrapper .banner-content-wrapper h1 {
		font-size: 29px;
	}

	.banner-sec img.banner-bg-img {
		height: 1110px;
	}
}

@media (max-width: 425px) {
	.register-banner-btn-wrapper span {
		font-size: 10px;
	}

	.register-banner-btn-wrapper span.big {
		font-size: 19px;
	}
}

@media (max-width: 414px) {
	/* .banner-slide-wrapper {
		padding: 48px 0px 21px 0px;
	} */
	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper1 {
		margin: 0px !important;
	}

	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper2 {
		margin: 0px !important;
	}

	.banner-sec .banner-explore-wrapper .banner-explore-card-wrapper3 {
		margin: 0px !important;
	}
}

@media (max-width: 375px) {
	.banner-slide-wrapper {
		padding: 95px 0px 21px 0px;
	}

	.banner-sec .banner-slide-wrapper .banner-content-wrapper h1 {
		font-size: 28px;
	}

	.banner-sec img.banner-bg-img {
		height: 1065px;
	}
}

@media (max-width: 360px) {
	.banner-sec .banner-slide-wrapper .banner-content-wrapper h1 {
		font-size: 26px;
	}
}

@media (max-width: 320px) {
	.banner-slide-wrapper {
		padding: 88px 0px 18px 0px;
	}

	.register-banner-btn-wrapper span {
		font-size: 10px;
	}

	.register-banner-btn-wrapper span.big {
		font-size: 18px;
	}

	.register-banner-btn-wrapper {
		top: 18%;
	}

	.banner-sec .banner-slide-wrapper .banner-content-wrapper h1 {
		font-size: 22px;
	}

	.banner-sec img.banner-bg-img {
		height: 1040px;
	}

	.register-banner-btn-wrapper {
		padding: 12px 7px 12px 14px;
	}
}

/* banner ends here */
/* explore starts here */
.explore {
	padding: 100px 0px;
}

.explore .explore-img-wrapper {
	height: 308px;
	width: 100%;
	overflow: hidden;
}

/* .explore .explore-img-wrapper figure::after {
	content: "";
	height: 100%;
	width: 100%;
	position: absolute;
	background: #00bbff7d;
	display: block;
	top: 0px;
	transform: translateY(-100%);
	transition: all 0.4s ease-in-out;
} */
.explore .explore-img-wrapper figure {
	/* overflow: hidden;
	position: relative; */
	margin: 0px;
}
.explore .explore-img-wrapper img {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
}

.explore .explore-img-wrapper img:hover {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}

/* .explore .explore-img-wrapper:hover figure::after {
	transform: translateY(0%);
} */
@media (max-width: 991px) {
	.explore .explore-img-wrapper img {
		width: 70%;
		margin: 0px auto 20px;
		display: table;
	}
}

@media (max-width: 768px) {
	.explore .explore-img-wrapper img {
		width: 90%;
	}

	.explore .slick-next {
		right: 0px;
	}

	.explore .slick-prev {
		left: 0px;
	}

	.explore .explore-img-wrapper figure::after {
		width: 90%;
		height: 88%;
		left: 5%;
	}
}

@media (max-width: 575px) {
	.explore {
		padding: 40px 0px;
	}
}

@media (max-width: 480px) {
	.explore .slick-prev {
		left: 8px;
	}

	.explore .slick-next {
		right: 7px;
	}
}

/* explore ends here */
/* divider starts here */
.explore-divider-wraper {
	border-bottom: 1px solid var(--default-text-color);
}

.explore-divider-wraper .divider-line {
	width: 30%;
	border: 1px solid var(--default-text-color);
}

/* divider ends here */
/* ABOUT sec starts here */
.about {
	padding: 100px 0px;
}

.about p {
	font-family: var(--font-light);
	font-size: 12px;
}

@media (max-width: 991px) {
	.about {
		padding: 100px 0px 50px;
	}

	.about .about-content-wrapper {
		text-align: center;
	}
}

@media (max-width: 575px) {
	.about {
		padding: 50px 0px 50px;
	}
}

/* ABOUT sec ends here */
/* TRENDING starts here */
.trending-sec {
	padding-top: 56px;
}

.trending-sec .trending-card-wrapper {
	display: flex !important;
	align-items: center;
}

.trending-sec .trending-card-wrapper .trending-img-wrapper {
	position: relative;
}

.trending-sec .trending-card-wrapper .trending-img-wrapper figure {
	margin: 0px;
}

.trending-sec .trending-card-wrapper .trending-content-wrapper {
	border: 1px solid var(--default-text-color);
	padding: 16px 20px 16px 113px;
	border-radius: 3px;
	margin-left: -90px;
}

.trending-sec .trending-card-wrapper .trending-content-wrapper h6 {
	margin: 0px;
	font-size: 20px;
}

.trending-sec .trending-card-wrapper .trending-img-wrapper figure img {
	clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.trending-sec .trending-card-wrapper .trending-content-wrapper:hover {
	background: var(--extra-color-3);
}

.trending-sec .trending-cards-row {
	/* display: flex; */
	justify-content: space-between;
	flex-wrap: wrap;
}

@media (max-width: 1440px) {
	.trending-sec .trending-cards-row {
		justify-content: space-evenly;
	}

	.trending-sec .trending-card-wrapper {
		margin-top: 10px;
	}

	.trending-sec .trending-card-wrapper {
		flex: 0 0 20%;
	}

	.trending-sec .trending-card-wrapper .trending-content-wrapper {
		padding: 16px 20px 16px 92px;
	}
}

@media (max-width: 768px) {
	.trending-sec .trending-cards-row {
		display: block;
	}

	.trending-sec .trending-cards-row .trending-card-wrapper {
		margin: 0px auto 20px;
		display: table !important;
		width: 40%;
	}

	.trending-sec
		.trending-cards-row
		.trending-card-wrapper
		.trending-content-wrapper {
		padding: 12px 10px;
		text-align: center;
		margin: 0px 10px;
	}

	.trending-sec .trending-cards-row .trending-card-wrapper img {
		margin: 0px auto 10px;
		display: table;
	}
}

/* TRENDING ends here */
/* TOP COLLECTIONS starts here */
.top-collection .top-heading-wrapper {
	text-align: center;
}

.top-collection a.coll-btn {
	display: flex;
	justify-content: end;
	font-family: var(--font-default);
	align-items: center;
	font-size: 14px;
	font-weight: 300 !important;
}

.top-collection a.coll-btn::before {
	content: "";
	width: 25px;
	height: 1px;
	display: block;
	background: #4b4b4b;
	margin-right: 18px;
}

.top-collection .profile-card-wrapper .profile-name-and-img-wrapper {
	display: flex;
	justify-content: space-between;
}

.profile-card-wrapper .profile-wishlist-wrapper button.btn {
	background: var(--extra-color-3);
	height: 40px;
	width: 40px;
	font-size: 16px;
	padding: 0px;
	border-radius: 50%;
	border: 1px solid #707070;
}

.top-collection .profile-card-wrapper {
	border: 1px solid var(--default-text-color);
	padding: 20px;
	margin: 0px 10px;
}

.top-collection
	.profile-card-wrapper
	.profile-name-and-img-wrapper
	.profile-img-space-wrapper {
	display: flex;
	align-items: center;
}

.top-collection
	.profile-card-wrapper
	.profile-name-and-img-wrapper
	.profile-img-space-wrapper
	.profile-img-wrapper {
	position: relative;
}

.top-collection
	.profile-card-wrapper
	.profile-name-and-img-wrapper
	.profile-img-space-wrapper
	.profile-img-wrapper
	img.verified {
	position: absolute;
	bottom: 0px;
	right: 0%;
}

.top-collection
	.profile-card-wrapper
	.profile-name-and-img-wrapper
	.profile-img-space-wrapper
	.profile-name-wrapper {
	margin-left: 14px;
}

.top-collection
	.profile-card-wrapper
	.profile-name-and-img-wrapper
	.profile-img-space-wrapper
	.profile-name-wrapper
	h5 {
	margin: 0px;
}

.top-collection
	.profile-card-wrapper
	.profile-name-and-img-wrapper
	.profile-img-space-wrapper
	.profile-name-wrapper
	p {
	margin: 0px;
	font-family: var(--font-light);
	font-size: 11px;
}

.top-collection
	.profile-card-wrapper
	.profile-name-and-img-wrapper
	.profile-img-space-wrapper
	.profile-name-wrapper
	p
	span {
	font-weight: 500;
	font-family: var(--font-semibold);
	color: var(--default-text-color);
}

.top-collection
	.profile-card-wrapper
	.profile-name-and-img-wrapper
	.profile-img-space-wrapper
	.profile-img-wrapper
	img.user-photo {
	height: 71px;
	width: 71px;
	border-radius: 50%;
	object-fit: cover;
	object-position: center;
}

.top-collection .profile-card-wrapper .profile-gallery-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin-top: 25px;
}

.top-collection .profile-card-wrapper .profile-gallery-wrapper .item1 {
	flex: 0 0 50%;
	position: relative;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
}

.top-collection .profile-card-wrapper .profile-gallery-wrapper .item1::after {
	background: #00bbff8f;
	content: "";
	height: 100%;
	width: 100%;
	display: block;
	position: absolute;
	z-index: 5;
	top: 0;
	left: 0;
	border-radius: 3px;
	transform: translateY(100%);
	transition: all 0.4s ease-in-out;
}

.top-collection .profile-card-wrapper .profile-gallery-wrapper .item1 img {
	position: relative;
}

/* .top-collection
	.profile-card-wrapper:hover
	.profile-gallery-wrapper
	.item1::after {
	transform: translateY(0%);
} */
.top-collection .profile-card-wrapper .profile-gallery-wrapper .item2 {
	flex: 0 0 50%;
	position: relative;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
}
.top-collection .profile-card-wrapper {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
}

.top-collection .profile-card-wrapper:hover {
	/* box-shadow: 0px 0px 8px 1px rgba(255, 255, 255, 0.7); */
	transition: all 0.3s ease-in-out;
}

/* .top-collection .profile-card-wrapper .profile-gallery-wrapper .item2::after {
	background: #00bbff8f;
	content: "";
	height: 100%;
	width: 100%;
	display: block;
	position: absolute;
	z-index: 5;
	top: 0;
	left: 0;
	border-radius: 3px;
	transform: translateY(100%);
	transition: all 0.4s ease-in-out;
} */
.top-collection .profile-card-wrapper .profile-gallery-wrapper .item2 img {
	position: relative;
}

/* .top-collection
	.profile-card-wrapper:hover
	.profile-gallery-wrapper
	.item2::after {
	transform: translateY(0%);
} */
.top-collection .profile-card-wrapper:hover {
	background: var(--extra-color-3);
	border: 0px;
}
.profile-card-wrapper:hover .profile-wishlist-wrapper button.btn {
	background: var(--extra-color-1);
}
.top-collection .profile-card-wrapper .profile-gallery-wrapper .item2 .pro3 {
	/* flex: 0 0 10%; */
	width: 47%;
	margin-left: 5px;
	height: 89px;
	object-fit: cover;
	display: inline-block;
}

.top-collection .profile-card-wrapper .profile-gallery-wrapper .item2 .pro2 {
	flex: 0 0 9%;
	margin-left: 5px;
	margin-bottom: 5px;
}

.top-collection .profile-card-wrapper .profile-gallery-wrapper .item2 .pro4 {
	/* flex: 0 0 14%; */
	width: 47%;
	margin-left: 5px;
	height: 89px;
	object-fit: cover;
	display: inline-block;
}
.top-collection .profile-card-wrapper:hover svg {
	opacity: 1;
}
.top-collection .profile-card-wrapper svg {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
}
.top-collection .profile-card-wrapper svg line {
	stroke-width: 3;
	stroke: #ecf0f1;
	fill: none;
	-webkit-transition: all 0.8s ease-in-out;
	transition: all 0.8s ease-in-out;
}

.top-collection .profile-card-wrapper:hover svg line {
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.top-collection .profile-card-wrapper svg line.top,
.top-collection .profile-card-wrapper svg line.bottom {
	stroke-dasharray: 330 240;
}

.top-collection .profile-card-wrapper svg line.left,
.top-collection .profile-card-wrapper svg line.right {
	stroke-dasharray: 490 400;
}

.top-collection .profile-card-wrapper:hover svg line.top {
	-webkit-transform: translateX(-600px);
	transform: translateX(-600px);
}

.top-collection .profile-card-wrapper:hover svg line.bottom {
	-webkit-transform: translateX(600px);
	transform: translateX(600px);
}

.top-collection .profile-card-wrapper:hover svg line.left {
	-webkit-transform: translateY(920px);
	transform: translateY(920px);
}
.top-collection .profile-card-wrapper:hover svg line.right {
	-webkit-transform: translateY(-920px);
	transform: translateY(-920px);
}
@media (max-width: 1440px) {
	.top-collection .profile-card-wrapper .profile-gallery-wrapper .item2 .pro3 {
		width: 46.5%;
		height: 75px;
	}

	.top-collection .profile-card-wrapper .profile-gallery-wrapper .item2 .pro4 {
		height: 75px;
	}
}

@media (max-width: 1200px) {
	.top-collection .profile-card-wrapper .profile-gallery-wrapper .item2 .pro4 {
		/* flex: 0 0 14%; */
		width: 45.5%;
	}
}

@media (max-width: 1100px) {
	.top-collection
		.profile-card-wrapper
		.profile-name-and-img-wrapper
		.profile-img-space-wrapper
		.profile-name-wrapper
		h5 {
		font-size: 16px;
	}

	.top-collection
		.profile-card-wrapper
		.profile-name-and-img-wrapper
		.profile-img-space-wrapper
		.profile-name-wrapper
		p {
		font-size: 11px;
	}
}

@media (max-width: 1024px) {
	.top-collection .profile-card-wrapper .profile-gallery-wrapper .item2 .pro4 {
		height: 54px;
	}

	.top-collection .profile-card-wrapper .profile-gallery-wrapper .item2 .pro3 {
		width: 44.5%;
		height: 54px;
	}

	.top-collection .profile-card-wrapper {
		margin: 0px 10px 20px 0px;
	}
}

@media (max-width: 991px) {
	.top-collection .slick-dots {
		bottom: -40px;
	}

	.top-collection .slick-dots li button:before {
		color: #fff;
	}
}

@media (max-width: 768px) {
	.top-collection .profile-card-wrapper .profile-gallery-wrapper .item2 .pro3 {
		height: 110px;
	}

	.top-collection .profile-card-wrapper .profile-gallery-wrapper .item2 .pro4 {
		height: 110px;
	}

	.top-collection .profile-card-wrapper .profile-gallery-wrapper .item1 img {
		width: 100%;
		height: 100%;
	}

	.top-collection .profile-card-wrapper .profile-gallery-wrapper .item2 .pro2 {
		width: 100%;
	}

	.top-collection
		.profile-card-wrapper
		.profile-gallery-wrapper
		.bottom-image
		img {
		width: 49% !important;
		border-radius: 5px;
	}

	.top-collection .profile-card-wrapper .profile-gallery-wrapper .item2 .pro4 {
		height: 155px;
		width: 47.5%;
	}

	.top-collection .profile-card-wrapper .profile-gallery-wrapper .bottom-image {
		display: flex;
	}

	.top-collection
		.profile-card-wrapper
		.profile-gallery-wrapper
		.bottom-image
		img {
		height: 100% !important;
	}
}

@media (max-width: 575px) {
	.top-collection .slick-dots {
		display: none !important;
	}

	.top-collection .profile-card-wrapper {
		padding: 20px 20px;
	}
}

/* TOP COLLECTIONS ends here */
/* promotion starts here */
.promotion {
	padding-bottom: 100px;
}

.promotion .promotion-card-1-wrapper {
	height: 300px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	background: url("../img/promotion1.png");
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: 0px;
	backdrop-filter: blur(10px);
}

.promotion-card-1-content-wrapper {
	-webkit-backdrop-filter: blur(27px);
	backdrop-filter: blur(27px);
	width: 100%;
	height: 100%;
	padding: 47px 0px;
	border-radius: 0px;
	background: #0000004a;
}

.promotion-card-1-content-wrapper p {
	color: var(--default-text-color);
}

.promotion-card-1-content-wrapper .btn {
	background: var(--extra-color-3);
	border-radius: 4px;
	color: var(--default-text-color);
	font-family: var(--font-default);
	padding: 10px 29px;
	margin-top: 11px;
	border: 1px solid transparent;
}

.promotion-card-1-content-wrapper .btn:hover {
	background: var(--extra-color-2) !important;
}

.promotion .promotion-card-2-wrapper {
	text-align: center;
	height: 300px;
	border: 1px solid var(--default-text-color);
	border-radius: 7px;
	padding: 50px 0px;
}

.promotion-card-2-content-wrapper .btn {
	background: var(--extra-color-3);
	border-radius: 4px;
	color: var(--default-text-color);
	font-family: var(--font-default);
	padding: 10px 29px;
	margin-top: 11px;
	border: 1px solid transparent;
}

.promotion-card-2-content-wrapper .btn:hover {
	background: var(--extra-color-2) !important;
}

.promotion .promotion-card-2-wrapper {
	text-align: center;
	height: 300px;
	border: 1px solid #828496;
	border-radius: 0px;
	padding: 50px 0px;
}

.promotion .promotion-card-2-wrapper .promotion-card-2-content-wrapper h4 {
	display: inline-block;
	background: var(--extra-color-2);
	clip-path: polygon(100% 0, 92% 48%, 100% 100%, 0 100%, 7% 52%, 0 0);
	padding: 3px 29px;
	border: 2px solid var(--default-text-color);
	color: #05092c;
	text-shadow: -2px 1px #0083b3;
	letter-spacing: 2px;
	margin-bottom: 25px;
}

@media (max-width: 991px) {
	.promotion .promotion-card-2-wrapper {
		margin-top: 20px;
	}
}

@media (max-width: 575px) {
	.promotion {
		padding-bottom: 50px;
	}

	.promotion .promotion-card-2-wrapper .promotion-card-2-content-wrapper p br {
		display: none;
	}
}

/* promotion ends here */
/* PHOTO SEC STARTS HERE */
.photo-sec .photo-illustration-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.photo-sec .photo-illustration-wrapper .photo-img-name-wrapper {
	display: flex;
	align-items: center;
}

.photo-sec
	.photo-illustration-wrapper
	.photo-img-name-wrapper
	.photo-name-wrapper
	h5 {
	font-family: var(--font-semibold);
	font-size: 25px;
}

.photo-sec
	.photo-illustration-wrapper
	.photo-img-name-wrapper
	.photo-name-wrapper
	h6 {
	font-family: var(--font-light);
	font-size: 12px;
	font-weight: 300;
}

.photo-sec
	.photo-illustration-wrapper
	.photo-img-name-wrapper
	.photo-name-wrapper
	h6
	span {
	font-family: var(--font-semibold);
}

.photo-sec
	.photo-illustration-wrapper
	.photo-img-name-wrapper
	.photo-img-wrapper
	img.user-photo {
	border-radius: 50%;
}

.photo-sec
	.photo-illustration-wrapper
	.photo-img-name-wrapper
	.photo-img-wrapper {
	position: relative;
}

.photo-sec
	.photo-illustration-wrapper
	.photo-img-name-wrapper
	.photo-img-wrapper
	img.verified {
	position: absolute;
	bottom: 9%;
	right: -3%;
}

.photo-sec
	.photo-illustration-wrapper
	.photo-img-name-wrapper
	.photo-name-wrapper {
	margin-left: 20px;
}

.photo-sec .photo-heading-wrapper {
	text-align: center;
}

.photo-sec .photo-heading-wrapper p {
	color: var(--default-text-color);
}

.photo-sec .photo-content-wrapper p:first-child span {
	color: var(--default-text-color);
	font-family: var(--font-semibold);
}

.photo-sec .photo-content-wrapper p {
	line-height: 2;
	margin-bottom: 32px;
	color: var(--default-text-color);
}

.photo-sec .photo-content-wrapper p:first-child {
	margin-bottom: 0px;
	font-family: var(--font-reg);
}

.photo-sec .photo-illustration-wrapper .photo-btn-wrapper a.btn {
	background: var(--extra-color-3);
	font-size: 14px;
}

.photo-sec .photo-illustration-wrapper .photo-btn-wrapper a.btn:hover {
	background: var(--extra-color-2) !important;
}

@media (max-width: 1200px) {
	.photo-sec .photo-illustration-wrapper {
		display: block;
	}
}

@media (max-width: 768px) {
	.photo-sec .photo-content-wrapper {
		text-align: center;
	}

	.photo-sec .photo-illustration-wrapper .photo-img-name-wrapper {
		justify-content: center;
		display: block;
	}

	.photo-sec
		.photo-illustration-wrapper
		.photo-img-name-wrapper
		.photo-img-wrapper {
		max-width: 80px;
		margin: 0px auto;
	}

	.photo-sec
		.photo-illustration-wrapper
		.photo-img-name-wrapper
		.photo-name-wrapper {
		margin-left: 0;
	}
}

/* PHOTO SEC STARTS HERE */
/* Featured Categories starts here */
.featured-cat .featured-content-wrapper {
	text-align: center;
}

.featured-cat {
	padding: 100px 0px;
}

.featured-cat button.all-cat {
	border: 1px solid var(--default-text-color);
	background: transparent;
	color: var(--default-text-color);
	padding: 9px 16px;
	border-radius: 5px;
	font-size: 14px;
}

.featured-cat .featured-content-wrapper p {
	font-size: 16px;
}

.featured-cat .all-cat-wrapper {
	margin-top: 34px;
}

.featured-cat .cat-card-wrapper {
	height: 263px;
	width: 313px;
	text-align: center;
	background-image: url("../img/cat1.png");
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: contain;
	background-position: center center;
}

.featured-cat .cat-fst-row-wrapper {
	display: flex;
	justify-content: space-around;
}

.featured-cat .cat-card-wrapper:hover {
	/* transform: scale(1.1); */
	z-index: 9;
	transition: all 0.4s ease-in-out;
}

.featured-cat .fst-cat {
	background-image: url("../img/cat1.png");
}

.featured-cat .sec-cat {
	background-image: url("../img/cat2.png");
}

.featured-cat .thir-cat {
	background-image: url("../img/cat3.png");
}

.featured-cat .four-cat {
	background-image: url("../img/cat4.png");
	position: relative;
	top: -142px;
	right: -7.5%;
}

.featured-cat .five-cat {
	background-image: url("../img/cat5.png");
	position: relative;
	top: -20px;
}

.featured-cat .six-cat {
	background-image: url("../img/cat6.png");
	position: relative;
	top: -140px;
	right: 7.5%;
}

.featured-cat .cat-sec-row-wrapper {
	display: flex;
	justify-content: center;
}

.featured-cat .cat-card-wrapper .cat-content-wrapper h4 {
	font-size: 21px;
}

.featured-cat .cat-card-wrapper:hover .cat-content-wrapper h4 {
	color: #00bbff;
}

.featured-cat
	.cat-card-wrapper.fst-cat
	.cat-content-wrapper
	.admin-name-img-wrapper {
	display: flex;
}

.featured-cat
	.cat-card-wrapper
	.cat-content-wrapper
	.admin-name-img-wrapper
	img {
	height: 33px;
	width: 33px;
}

.featured-cat .cat-card-wrapper .cat-content-wrapper span {
	font-size: 12px;
	font-family: var(--font-light);
	margin-left: 10px;
}

.featured-cat .cat-card-wrapper .cat-content-wrapper .admin-img-wrapper {
	background: #f1f1f147;
	padding: 6px 13px;
	border-radius: 30px;
	-webkit-backdrop-filter: blur(41px);
	backdrop-filter: blur(3px);
}

/* RESPONSIVE STARTS HERE */
@media (max-width: 1440px) {
	.featured-cat .cat-card-wrapper {
		height: 230px;
		width: 275px;
	}

	.featured-cat .four-cat {
		top: -125px;
		right: -7.9%;
	}

	.featured-cat .six-cat {
		position: relative;
		top: -126px;
		right: 8%;
	}
}

@media (max-width: 1200px) {
	.featured-cat .cat-card-wrapper {
		position: unset;
	}

	.featured-cat .cat-sec-row-wrapper {
		justify-content: space-around;
	}
}

@media (max-width: 991px) {
	.featured-cat {
		padding: 100px 0px 50px;
	}

	.featured-cat .cat-card-wrapper .cat-content-wrapper h4 {
		font-size: 15px;
	}

	.featured-cat .cat-card-wrapper .cat-content-wrapper .admin-img-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.featured-cat .cat-card-wrapper .cat-content-wrapper .admin-img-wrapper img {
		margin: 0px auto 4px;
	}
}

@media (max-width: 768px) {
	.featured-cat .cat-fst-row-wrapper {
		flex-wrap: wrap;
	}

	.featured-cat .cat-sec-row-wrapper {
		flex-wrap: wrap;
	}
}

@media (max-width: 575px) {
	.featured-cat {
		padding: 60px 0px 20px;
	}
}

/* RESPONSIVE ENDS HERE */

/* Featured Categories ends here */

/* promotion starts here */
.join {
	padding-bottom: 100px;
	padding-top: 50px;
}

.join .promotion-card-1-wrapper {
	height: 300px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	background: url("../img/join-card1.png");
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: 7px;
	backdrop-filter: blur(10px);
}

.join .promotion-card-1-content-wrapper {
	width: 100%;
	height: 100%;
	padding: 47px 0px;
	border-radius: 0px;
	background: #ffffff1c;
}

.join .promotion-card-1-content-wrapper .btn {
	background: var(--extra-color-3);
	border-radius: 4px;
	color: var(--default-text-color);
	font-family: var(--font-default);
	padding: 10px 29px;
	margin-top: 11px;
	border: 1px solid transparent;
}

.join .promotion-card-2-wrapper {
	text-align: center;
	height: 300px;
	border: 1px solid var(--default-text-color);
	border-radius: 7px;
	padding: 50px 0px;
}

.join .promotion-card-2-content-wrapper .btn {
	background: var(--extra-color-3);
	border-radius: 4px;
	color: var(--default-text-color);
	font-family: var(--font-default);
	padding: 10px 29px;
	margin-top: 11px;
	border: 1px solid transparent;
}

.join .promotion-card-2-wrapper {
	text-align: center;
	height: 300px;
	border: 1px solid #828496;
	border-radius: 0px;
	padding: 50px 0px;
}

.join .promotion-card-2-wrapper .promotion-card-2-content-wrapper h4 {
	display: inline-block;
	background: var(--extra-color-2);
	clip-path: polygon(100% 0, 92% 48%, 100% 100%, 0 100%, 7% 52%, 0 0);
	padding: 3px 29px;
	border: 2px solid var(--default-text-color);
	color: #05092c;
	text-shadow: -2px 1px #0083b3;
	letter-spacing: 2px;
	margin-bottom: 25px;
}

.join .promotion-card-2-wrapper .promotion-social-wrapper ul {
	list-style: none;
	padding: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.join .promotion-card-2-wrapper .promotion-social-wrapper ul li {
	margin: 0px 9px;
}

.join .promotion-card-2-wrapper .promotion-social-wrapper ul li a {
	width: 46px;
	height: 45px;
	display: inline-flex;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	border: 1px solid #828496;
}

.join .promotion-card-2-wrapper .promotion-card-2-content-wrapper h5 {
	font-family: var(--font-heading);
	font-size: 30px;
}

.join
	.promotion-card-2-wrapper
	.promotion-social-wrapper
	ul
	li
	a.facebook:hover {
	background: #3b5998;
	border: 1px solid #3b5998;
	transition: all 0.4s ease-in-out;
}

.join
	.promotion-card-2-wrapper
	.promotion-social-wrapper
	ul
	li
	a.twitter:hover {
	background: #0072b1;
	border: 1px solid #0072b1;
	transition: all 0.4s ease-in-out;
}

.join
	.promotion-card-2-wrapper
	.promotion-social-wrapper
	ul
	li
	a.instagram:hover {
	background: linear-gradient(
		45deg,
		#405de6,
		#5851db,
		#833ab4,
		#c13584,
		#e1306c,
		#fd1d1d
	);
	border: 1px solid #405de6;
	transition: all 0.4s ease-in-out;
}

.join
	.promotion-card-2-wrapper
	.promotion-social-wrapper
	ul
	li
	a.pinterest:hover {
	background: #c8232c;
	border: 1px solid #c8232c;
	transition: all 0.4s ease-in-out;
}

.join
	.promotion-card-2-wrapper
	.promotion-social-wrapper
	ul
	li
	a.youtube:hover {
	background: #ff0000;
	border: 1px solid #ff0000;
	transition: all 0.4s ease-in-out;
}

@media (max-width: 991px) {
	.join {
		padding-bottom: 50px;
	}

	.join .promotion-card-2-wrapper {
		margin-top: 30px;
	}

	.counter-sec .counter-wrapper {
		margin-bottom: 25px;
	}
}

@media (max-width: 575px) {
	.join {
		padding-bottom: 10px;
	}

	.join .promotion-card-2-wrapper .promotion-card-2-content-wrapper h5 {
		font-size: 25px;
	}
}

@media (max-width: 320px) {
	.join .promotion-card-2-wrapper .promotion-social-wrapper ul li a {
		width: 40px;
		height: 42px;
	}
}

/* promotion ends here */
/* NEWSLETTER starts here */
.newsletter {
	padding: 30px 0px;
}

.newsletter .row {
	padding: 25px 30px;
	border: 1px solid #828496;
	border-radius: 10px;
}

.newsletter .newsletter-content-wrapper p {
	opacity: 60%;
}

.newsletter .form-newsletter-wrapper input {
	height: 47px;
	background: #141737;
	font-family: var(--font-default);
	color: #fff;
	font-size: 13px;
	border: 1px solid #828496;
}

.newsletter .form-newsletter-wrapper input::placeholder {
	color: var(--default-text-color);
	opacity: 48%;
}

.newsletter .form-newsletter-wrapper button.btn {
	margin: 0px;
	border-radius: 0px 5px 6px 0px;
	height: 47px;
}

.newsletter .form-newsletter-wrapper button.btn {
	background: var(--extra-color-3);
	border: 1px solid var(--extra-color-3);
}

.newsletter .form-newsletter-wrapper button.btn:hover {
	background: var(--extra-color-2) !important;
	border-color: var(--extra-color-2) !important;
}

@media (max-width: 575px) {
	.newsletter .row {
		margin: 0 0;
	}

	.newsletter .row {
		padding: 20px 10px;
	}
}

/* NEWSLETTER ends here */

/* COUNTER STARTS HERE */
.counter-sec .counter-wrapper {
	text-align: center;
}

.counter-sec .row {
	padding: 20px;
	background: #004c66;
}

.counter-sec {
	padding: 30px 0px;
}

.counter-sec .counter-wrapper h2 {
	line-height: 1;
	font-size: 60px;
	margin: 0px;
}

.counter-sec .counter-wrapper h5 {
	font-size: 25px;
}

@media (max-width: 1200px) {
	.counter-sec .counter-wrapper h5 {
		font-size: 18px;
	}
}

@media (max-width: 575px) {
	.counter-sec .counter-wrapper h2 {
		font-size: 45px;
	}
}

/* COUNTER ENDS HERE */
/* Footer starts here */
footer {
	padding: 100px 0px 30px 0px;
	border-top: 1px solid #828496;
	border-bottom: 1px solid #828496;
}

.footer-widget-wrapper .footer-logo-wrapper img {
	width: 80%;
}

footer ul {
	list-style: none;
	padding: 0px;
}

footer ul li a {
	line-height: 2;
	font-family: var(--font-light);
	font-size: 13px;
	opacity: 80%;
}

footer ul li a i {
	color: var(--extra-color-3);
	margin-right: 4px;
}

footer .footer-2-widget-wrapper .footer-links-wrapper {
	margin-top: 31px;
}

footer .footer-2-widget-wrapper .footer-links-wrapper li a {
	display: flex;
	align-items: baseline;
	margin-bottom: 4px;
}

footer .footer-2-widget-wrapper .footer-links-wrapper li a span {
	margin-left: 11px;
}

footer ul.social-icon img {
	width: 36px;
	height: 36px;
	object-fit: cover;
	border-radius: 50%;
}

footer ul.social-icon img:hover {
	transition: all 0.4s ease-in-out;
	transform: scale(1.1);
}

footer ul.social-icon {
	display: flex;
	margin-bottom: 34px;
}

footer ul.social-icon li {
	margin: 0px 12px 0px 0px;
}

footer .footer-heading h5 {
	font-family: var(--font-default);
	font-weight: 400;
	font-size: 20px;
}

footer .footer-2-widget-wrapper .email-wrapper a {
	color: var(--default-text-color);
	opacity: 0.5;
	font-size: 12px;
}

footer .footer-widget-wrapper .footer-content-wrapper {
	margin-top: 17px;
	width: 77%;
	font-family: var(--poppins-reg);
	margin-top: 30px;
}

div#copyright p,
div#copyright ul {
	margin: 0px;
	font-size: 10px;
}

div#copyright .footer-menu-wrapper ul {
	display: flex;
	list-style: none;
	flex-wrap: wrap;
	padding: 0px;
	justify-content: space-around;
	margin: 0px 29px;
}

div#copyright .footer-menu-wrapper ul li a {
	font-size: 10px;
	font-family: var(--poppins-reg);
	color: var(--text-color);
}

div#copyright {
	padding: 30px 0px;
}

footer .footer-widget-wrapper .footer-content-wrapper p {
	font-size: 10px;
}

@media (max-width: 1366px) {
	footer ul li a {
		line-height: 2;
		font-family: var(--font-light);
		font-size: 11px;
	}
}

@media (max-width: 1200px) {
	footer .footer-widget-wrapper .footer-content-wrapper {
		width: 100%;
	}

	footer ul li a {
		line-height: 2;
		font-size: 10px;
	}

	div#copyright .footer-menu-wrapper ul li a {
		font-size: 11px;
	}
}

@media (max-width: 991px) {
	footer {
		padding: 50px 0px;
	}

	footer .footer-logo-wrapper {
		max-width: 50%;
	}

	footer ul li a i {
		color: #ffffff96;
	}

	.join .promotion-card-2-wrapper {
		margin-top: 30px;
	}

	#copyright .footer-menu-wrapper ul {
		justify-content: center !important;
	}

	#copyright .footer-menu-wrapper ul li a {
		font-size: 14px !important;
		margin: 0px 5px !important;
	}

	#copyright .footer-menu-wrapper ul li {
		margin-top: 10px !important;
	}
}

@media (max-width: 575px) {
	.join .promotion-card-2-wrapper p br {
		display: none;
	}
}

/* Footer Ends here */
/* Similar Images Start Here */
section.similarimages .imageslist {
	padding: 60px 0px;
}

.review-form .heading_wrapper,
.similarimages .heading_wrapper {
	padding-bottom: 15px;
	border-bottom: 1px solid #ffffff;
	position: relative;
}

.review-form .heading_wrapper::before,
.similarimages .heading_wrapper:before {
	content: "";
	background: #fff;
	width: 20%;
	height: 3px;
	position: absolute;
	left: 0;
	bottom: -2px;
}

.similarimages .video-timer {
	position: absolute;
	top: 15px;
	display: flex;
	align-items: center;
	left: 22px;
}

.similarimages .video-timer .icon svg {
	background: #004c66;
	width: 35px;
	height: 35px;
	padding: 7px 8px;
	border-radius: 50%;
	border: 5px solid #ffffffb0;
	cursor: pointer;
}

.similarimages .video-timer .time {
	margin: 0;
	font-size: 16px;
	font-family: "Vietnam-reg";
	padding-left: 15px;
}

section.similarimages .imageslist figure {
	position: relative;
	margin-bottom: 0px;
	height: 100%;
}

section.similarimages .imageslist figure img {
	height: 100%;
	object-fit: cover;
	width: 100%;
}

/* Similar End Here */
/* Reviews Start Here */
.form-group label span {
	color: var(--extra-color-2);
}

.review-form .form_start .form-control {
	height: 47px;
	border-radius: 4px;
	background: #141737;
	color: var(--extra-color-4);
	font-family: var(--font-light);
	font-size: 12px;
	margin-bottom: 20px;
	resize: none;
}

.review-form .form_start textarea {
	height: 115px !important;
}

.review-form .form_start .form-control::placeholder {
	color: var(--extra-color-4);
}

.review-form .form_start .form-group label {
	margin-bottom: 10px;
	font-family: var(--font-default);
	font-size: 15px;
}

.review-form .form_start .form-group {
	margin-bottom: 22px;
}

section.review-form {
	padding: 39px 0px 69px;
}
