.terms {
	background-image: url("../img/login-bg.png");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: top center;
	padding: 167px 0px 80px 0px;
}
.terms .terms-content-wrapper {
	border: 1px solid #828496;
	padding: 41px;
	border-radius: 8px;
	margin-top: 92px;
}

.terms .terms-content-wrapper h5 {
	font-size: 25px;
	font-family: var(--font-semibold);
}
.terms .terms-content-wrapper p {
	font-family: var(--font-light);
	font-size: 16px;
	font-weight: 300;
	margin: 30px 0px;
	line-height: 27px;
	opacity: 70%;
}
/* responsive starts here */
@media (max-width: 480px) {
	.terms .terms-content-wrapper {
		padding: 19px;
	}
}
/* responsive ends here */
