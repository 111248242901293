.Artist .uploaded-work-wrapper {
	padding: 50px 50px;
	border: 1px solid #828496;
	border-radius: 6px;
}
.uploaded-table-wrapper th,
.uploaded-table-wrapper td {
	border: 0px;
}
.uploaded-table-wrapper th {
	color: #fff;
	font-size: 20px;
	font-family: var(--font-default);
	font-weight: 700;
	padding: 16px 0px;
}
.uploaded-table-wrapper td.img-wrapper figure {
	margin: 0;
}
.uploaded-table-wrapper td.img-wrapper img {
	height: 81px;
	width: 81px;
	object-fit: cover;
}
.uploaded-table-wrapper td.title-head h5 {
	color: #fff;
	font-size: 20px;
	font-family: var(--font-default);
	font-weight: 400;
}
.uploaded-table-wrapper td.actions-btn-wrapper .btn-wrapper {
	display: flex;
	justify-content: space-between;
}
.uploaded-table-wrapper td.actions-btn-wrapper .btn-wrapper a {
	border-right: 1px solid;
	color: var(--extra-color-2);
	font-family: var(--font-default);
	padding: 0 10px 0 9px;
}
.uploaded-table-wrapper td.actions-btn-wrapper .btn-wrapper a:last-child {
	border: 0px;
}
.uploaded-table-wrapper td {
	vertical-align: middle;
}
.uploaded-work-wrapper .uploaded-table-wrapper {
	margin-top: 30px;
	overflow-y: scroll;
	height: 650px;
}
.uploaded-work-wrapper .uploaded-table-wrapper::-webkit-scrollbar {
	width: 3px;
	background-color: #fff;
}
.uploaded-work-wrapper .uploaded-table-wrapper::-webkit-scrollbar-thumb {
	background-color: var(--extra-color-2) !important;
	width: 4px;
}
.uploaded-table-wrapper tbody td {
	padding-bottom: 35px;
}
/* responsive starts here */
@media (max-width: 1280px) {
	.uploaded-table-wrapper td.title-head h5 {
		font-size: 16px;
	}
}
@media (max-width: 480px) {
	.Artist .uploaded-work-wrapper {
		padding: 28px 16px;
	}
	.uploaded-table-wrapper td.title-head h5 {
		font-size: 10px;
	}
	.uploaded-table-wrapper td.actions-btn-wrapper .btn-wrapper a {
		font-size: 9px;
	}
	.uploaded-table-wrapper tbody td {
		padding-bottom: 13px;
	}
	.uploaded-table-wrapper td.title-head h5 {
		font-size: 10px;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.uploaded-table-wrapper tbody td.img-wrapper {
		min-width: 120px;
	}
}
