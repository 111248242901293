/* Font cdn starts here */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@font-face {
	font-family: "Handel-Gothic";
	src: url("../fonts/Handel-Gothic-Bold.otf");
}
@font-face {
	font-family: "Vietnam-light";
	src: url("../fonts/BeVietnamPro-Light.ttf");
}
@font-face {
	font-family: "Vietnam-italic";
	src: url("../fonts/BeVietnamPro-Italic.ttf");
}
@font-face {
	font-family: "Vietnam-reg";
	src: url("../fonts/BeVietnamPro-Regular.ttf");
}
@font-face {
	font-family: "Vietnam-med";
	src: url("../fonts/BeVietnamPro-Medium.ttf");
}
@font-face {
	font-family: "Vietnam-semibold";
	src: url("../fonts/BeVietnamPro-SemiBold.ttf");
}
@font-face {
	font-family: "Vietnam-bold";
	src: url("../fonts/BeVietnamPro-Bold.ttf");
}
@font-face {
	font-family: "montserrat-reg";
	src: url("../fonts/montserrat-regular.ttf");
}
/* Font cdn ends here */
:root {
	/* Typography starts here */
	--font-default: "Vietnam-reg";
	--font-heading: "Handel-Gothic";
	--font-light: "Vietnam-light";
	--font-italic: "Vietnam-italic";
	--font-medium: "Vietnam-med";
	--font-montserrat-reg: "montserrat-reg";
	--font-semibold: "Vietnam-semibold";
	--font-bold: "Vietnam-bold";
	--poppins: "poppins";
	/* Typography ends here */

	/* Colors starts here */
	--body-color: #05092c;
	--extra-color-1: #05092c;
	--extra-color-2: #00bbff;
	--extra-color-3: #004c66;
	--extra-color-4: #bbbbbb;
	--default-text-color: #fff;
	/* --text-color: #848696; */
	--text-color: #b3b3ba;
	--font-heading-color: #fff;
	--light-background-color: #7a7a7a38;
	--light-dark-color: #707070;
	/* Colors ends here */

	/* button colors starts here */
	--primary-btn-color: #00bbff;
	--secondary-btn-color: #004c66;
	--button-detail-back: #d50012;
	--button-text-color: #fff;
	/* button colors starts here */
}
