.login-sec {
	background-image: url("../img/login-bg.png");
	background-repeat: no-repeat;
	background-size: contain;
	padding: 205px 0px 300px 0px;
}
.login-sec .login-card-wrapper {
	padding: 60px 55px;
	border: 1px solid #ffffff70;
	border-radius: 6px;
}
.login-sec .login-card-wrapper .login-heading-wrapper {
	text-align: center;
}
.login-sec .login-card-wrapper .login-form-wrapper .form-group {
	margin-bottom: 15px;
}
.login-sec .login-card-wrapper .login-form-wrapper .form-group label {
	margin-bottom: 10px;
	font-family: var(--font-semibold);
}
.login-sec
	.login-card-wrapper
	.login-form-wrapper
	.form-group
	.pass-label-wrapper {
	display: flex;
	justify-content: space-between;
}
.login-sec .login-card-wrapper .login-form-wrapper .form-group label span {
	color: var(--extra-color-2);
}
.login-sec
	.login-card-wrapper
	.login-form-wrapper
	.form-group
	.pass-label-wrapper
	a {
	color: var(--extra-color-2);
}
.login-sec .login-card-wrapper .login-form-wrapper input.form-control {
	height: 47px;
	border-radius: 4px;
	background: #141737;
	color: #bbbbbb;
	font-family: var(--font-light);
	font-size: 12px;
	margin-bottom: 20px;
}
.login-sec .login-card-wrapper button.btn.form-control {
	height: 53px;
}
.login-sec .login-card-wrapper button.btn.form-control:hover {
	background: var(--extra-color-2);
}
.login-sec .login-card-wrapper .login-heading-wrapper a {
	color: var(--extra-color-2);
}
/* responsive starts here */
@media (max-width: 1024px) {
	.login-sec {
		background-size: 100% 19%;
	}
}
@media (max-width: 480px) {
	.login-sec .login-card-wrapper {
		padding: 60px 31px;
	}
}
@media (max-width: 375px) {
	.login-sec .login-card-wrapper {
		padding: 27px 8px;
	}
	.login-sec .login-card-wrapper a.btn.form-control.res {
		height: 56px;
		line-height: 2;
		font-size: 16px;
	}
}
