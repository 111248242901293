.Artist .earning-card-wrapper {
  border: 1px solid #828496;
  border-radius: 8px;
}

.Artist .earning-card-wrapper .my-earnings-wrapper {
  padding: 50px 50px 0px 50px;
}
.Artist
  .earning-card-wrapper
  .my-earnings-wrapper
  .my-earnings-heading-wrapper {
  display: flex;
  align-items: center;
}
.Artist
  .earning-card-wrapper
  .my-earnings-wrapper
  .my-earnings-heading-wrapper
  h3 {
  font-size: 40px;
}
.Artist
  .earning-card-wrapper
  .my-earnings-wrapper
  .my-earnings-heading-wrapper
  span {
  border: 1px solid #cecece;
  padding: 6px 14px;
  display: flex;
  align-items: center;
  background: #022a49;
  margin-left: 30px;
}
.Artist
  .earning-card-wrapper
  .my-earnings-wrapper
  .my-earnings-heading-wrapper
  span
  img {
  margin-right: 15px;
}
.Artist
  .earning-card-wrapper
  .my-earnings-wrapper
  .my-earnings-heading-wrapper
  span
  h6 {
  margin: 0px;
  font-size: 14px;
  font-family: var(--poppins);
  font-weight: 400;
}
.Artist .earning-card-wrapper .download-cards-wrapper {
  padding: 24px 50px 50px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Artist .earning-card-wrapper .download-cards-wrapper .download-card-wrapper {
  flex: 0 0 25%;
  border: 1px solid #828496;
  text-align: center;
  padding: 35px 10px;
  border-radius: 8px;
  margin-right: 6px;
}
.Artist
  .earning-card-wrapper
  .download-cards-wrapper
  .download-card-wrapper
  h4 {
  font-size: 28px;
}
.Artist
  .earning-card-wrapper
  .download-cards-wrapper
  .download-card-wrapper
  h5 {
  font-size: 21px;
}
.Artist .earning-card-wrapper .divider-wrapper {
  border-bottom: 1px solid #828496;
}
.Artist .earning-card-wrapper .divider-wrapper .divide {
  width: 30%;
  height: 2px;
  background: #fff;
}
.Artist .earning-card-wrapper .current-month-wrapper {
  padding: 50px 50px;
}
.Artist .earning-card-wrapper .current-month-wrapper .current-table-wrapper {
  padding-top: 36px;
}
.Artist .earning-card-wrapper .current-month-wrapper .current-table-wrapper th {
  font-size: 20px;
  font-family: var(--font-bold);
  color: #fff;
  padding-bottom: 32px;
  text-align: center;
}
.Artist
  .earning-card-wrapper
  .current-month-wrapper
  .current-table-wrapper
  td
  h5 {
  font-size: 15px;
  font-family: var(--font-semibold);
  color: #fff;
  padding-bottom: 32px;
  margin-bottom: 0px;
  text-align: center;
}
.Artist .earning-card-wrapper .current-month-wrapper .current-table-wrapper td {
  vertical-align: middle;
  border: 0px;
}
.Artist
  .earning-card-wrapper
  .current-month-wrapper
  .current-table-wrapper
  td
  .pro-img-wrapper
  img {
  height: 71px;
  width: 69px;
  border: 2px solid #707070;
  border-radius: 7px;
  object-fit: cover;
}
.Artist
  .earning-card-wrapper
  .current-month-wrapper
  .current-table-wrapper
  td.pro-img {
  width: 10%;
}
.Artist .earning-card-wrapper .current-month-wrapper .current-table-wrapper {
  padding-top: 36px;
  height: 398px;
  overflow-y: scroll;
}
.Artist
  .earning-card-wrapper
  .current-month-wrapper
  .current-table-wrapper::-webkit-scrollbar {
  width: 3px;
  background-color: #fff;
}
.Artist
  .earning-card-wrapper
  .current-month-wrapper
  .current-table-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--extra-color-2);
}
.Artist .earning-card-wrapper .prev-wrapper {
  padding: 50px 50px;
}
.Artist .earning-card-wrapper .prev-wrapper .prev-heading-wrapper h2 {
  color: var(--default-text-color);
  font-size: 40px;
}
.Artist .prev-wrapper .prev-table-wrapper {
  height: 223px;
  overflow-y: scroll;
  overflow-x: auto !important;
}
.Artist .prev-wrapper .prev-table-wrapper::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #fff;
}
.Artist .prev-wrapper .prev-table-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--extra-color-2);
}
.Artist .earning-card-wrapper .prev-wrapper .prev-heading-wrapper {
  padding-bottom: 31px;
}
.Artist .earning-card-wrapper .prev-wrapper .prev-table-wrapper th {
  width: 33%;
  padding: 20px 0px;
  font-size: 20px;
}
.Artist .earning-card-wrapper .prev-wrapper .prev-table-wrapper table {
  width: 100%;
}
.Artist .earning-card-wrapper .prev-wrapper .prev-table-wrapper tbody td {
  font-family: var(--font-semibold);
  padding: 12px 0px;
}
.Artist
  .earning-card-wrapper
  .prev-wrapper
  .prev-table-wrapper
  tbody
  td
  span.pending {
  color: var(--extra-color-2);
}
.Artist
  .earning-card-wrapper
  .prev-wrapper
  .prev-table-wrapper
  tbody
  td
  span.paid {
  color: #00ff19;
}
.Artist
  .earning-card-wrapper
  .current-month-wrapper
  .current-table-wrapper
  table::-webkit-scrollbar-thumb {
  background-color: green;
  width: 2px;
}
/* responsive starts here */
@media (max-width: 1280px) {
  .Artist
    .earning-card-wrapper
    .download-cards-wrapper
    .download-card-wrapper
    h5 {
    font-size: 19px;
  }
}
@media (max-width: 480px) {
  .Artist
    .earning-card-wrapper
    .current-month-wrapper
    .current-table-wrapper
    th
    span {
    width: 109px;
    display: block;
  }
  .Artist .earning-card-wrapper .prev-wrapper .prev-table-wrapper th span {
    width: 128px;
    display: block;
  }
  .Artist .earning-card-wrapper .download-cards-wrapper {
    padding: 24px 14px 36px 14px;
  }
  .Artist .earning-card-wrapper .download-cards-wrapper {
    padding: 24px 14px 36px 14px;
    flex-direction: column;
  }
  .Artist .earning-card-wrapper .download-cards-wrapper .download-card-wrapper {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 15px;
  }
  .Artist .earning-card-wrapper .my-earnings-wrapper {
    padding: 50px 27px 0px 27px;
  }
  .Artist
    .earning-card-wrapper
    .my-earnings-wrapper
    .my-earnings-heading-wrapper {
    align-items: flex-start;
    flex-direction: column;
  }
  .Artist
    .earning-card-wrapper
    .my-earnings-wrapper
    .my-earnings-heading-wrapper
    span {
    margin-left: 0px;
    margin-top: 27px;
  }
  .Artist .earning-card-wrapper .current-month-wrapper {
    padding: 39px 20px;
  }
  .Artist
    .earning-card-wrapper
    .current-month-wrapper
    .current-table-wrapper
    th {
    font-size: 11px;
  }
  .Artist
    .earning-card-wrapper
    .current-month-wrapper
    .current-table-wrapper
    td
    h5 {
    font-size: 11px;
  }
  .Artist .earning-card-wrapper .prev-wrapper {
    padding: 39px 20px;
  }
  .Artist .earning-card-wrapper .prev-wrapper .prev-heading-wrapper h2 {
    font-size: 21px;
  }
  .Artist .earning-card-wrapper .prev-wrapper .prev-table-wrapper th {
    width: 33%;
    padding: 20px 0px;
    font-size: 13px;
  }
  .Artist .earning-card-wrapper .prev-wrapper .prev-table-wrapper tbody td {
    font-family: var(--font-semibold);
    padding: 12px 0px;
    font-size: 10px;
  }
  .Artist
    .earning-card-wrapper
    .current-month-wrapper
    .current-table-wrapper
    td.pro-img {
    width: 29%;
  }
  .Artist
    .earning-card-wrapper
    .current-month-wrapper
    .current-table-wrapper
    td.pro-name {
    width: 28%;
  }
  .Artist
    .earning-card-wrapper
    .current-month-wrapper
    .current-table-wrapper
    td.pro-price {
    width: 27%;
  }
  .artistSidebar .artist-logout-btn-wrapper a.btn {
    width: 100%;
    margin-bottom: 30px;
  }
}
@media (max-width: 425px) {
  .Artist .earning-card-wrapper .my-earnings-wrapper {
    padding: 50px 10px 0px 10px;
  }
  .Artist
    .earning-card-wrapper
    .current-month-wrapper
    .current-month-heading-wrapper
    h2 {
    font-size: 19px;
  }
}
@media (max-width: 375px) {
  .Artist .earning-card-wrapper .chart-wrapper canvas {
    height: 162px !important;
  }
}
/* responsive ends here */
