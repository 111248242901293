/* Thank You Css Start Here */
.thank_you {
	padding-top: 148px;
	padding-bottom: 150px;
	background-image: url("../img/login-bg.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top center;
}
.thank_you .heading_wrapper {
	text-align: center;
	margin-bottom: 40px;
}
.thank_you .heading_wrapper h2 {
	margin: 0;
}
.thank_you .heading_wrapper h4 {
	color: var(--extra-color-2);
}
.thank_you .heading_wrapper .back-button a {
	color: var(--extra-color-2);
	font-family: var(--font-montserrat-reg);
}
.thank_you .heading_wrapper .back-button {
	padding-bottom: 15px;
}
.thank_you .heading_wrapper .note {
	font-size: 22px;
}
.thank_you .product-detail {
	border: 2px solid #ffffff8f;
	border-radius: 10px;
	padding: 30px 30px;
}
.thank_you .product-detail .title {
	padding-bottom: 45px;
	text-align: center;
}
.thank_you .cart-box {
	padding: 30px 70px 20px 70px;
	max-width: 80%;
	margin: 0px auto 50px;
}
.thank_you .cart-box h2 {
	color: var(--extra-color-2);
}
.thank_you .cart-box p {
	font-size: 14px;
	line-height: 30px;
}
.thank_you .product-detail {
	border: 2px solid #ffffff8f;
	border-radius: 10px;
	padding: 30px 30px;
	max-width: 90%;
	margin: 0px auto;
	background: transparent;
}
.thank_you .product-detail .title {
	padding-bottom: 45px;
	text-align: center;
}
.thank_you .product-detail .item-total {
	border-top: 1px solid #c5c5c5;
	padding: 40px 0 0px;
	border-bottom: 1px solid #c5c5c5;
}
.thank_you .product-detail h4 .value {
	font-family: "Poppins";
	font-size: 18px;
	font-weight: 600;
}
.thank_you .product-detail .item-total h4 {
	font-size: 17px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
	text-transform: uppercase;
}
.thank_you .product-detail .item-total .total {
	display: flex;
	align-items: center;
	justify-content: end;
}
.thank_you .product-detail .order-total {
	padding-top: 25px;
}
.tahnk-wrapp .product-detail {
	background: transparent;
	border: 0px;
	padding: 0px;
}
.thank_you .product-detail .order-total h4 {
	font-size: 17px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-transform: uppercase;
}
.thank_you .product-detail .order-total h4 .property {
	color: var(--extra-color-2);
}
.thank_you .button-group {
	max-width: 90%;
	margin: 20px auto 0;
}
.thank_you .button-group a {
	width: 100%;
}
/* thank you product starts here */
.thank_you .beach-card-wrapper {
	display: flex;
	align-items: center;
	flex: 0 0 45%;
	justify-content: space-between;
}
.thank_you .beach-card-wrapper .bech-content-wrapper {
	flex: 0 0 60%;
	margin-left: 21px;
}
.thank_you .beach-card-wrapper .bech-content-wrapper h5,
.thank_you .beach-card-wrapper .bech-content-wrapper h6 {
	line-height: 1;
}
.thank_you .beach-card-wrapper .beach-img-wrapper {
	flex: 0 0 40%;
}
.thank_you .beachs-cards-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 19px 0px;
	border-bottom: 1px solid #828496;
}
.tahnk-wrapp {
	padding: 37px 20px;
	border: 1px solid #828496;
	border-radius: 11px;
}
.thank_you .beach-card-wrapper .beach-img-wrapper img {
	width: 163px;
	height: 131px;
	object-fit: cover;
	border-radius: 4px;
}
.thank_you .beach-card-wrapper .bech-content-wrapper h5 {
	font-size: 18px;
	font-weight: 700;
}
.thank_you .beach-card-wrapper .bech-content-wrapper h4 {
	font-size: 22px;
	color: var(--extra-color-2);
	line-height: 1;
}
.thank_you .beach-card-wrapper .bech-content-wrapper h6 {
	font-size: 15px;
	font-weight: 400;
}
.thank_you .beach-card-wrapper .bech-content-wrapper a.btn {
	width: 80%;
	padding: 10px 0px;
}
.thank_you .beach-card-wrapper .bech-content-wrapper a.btn span {
	margin-left: 22px;
}
.thank_you .beach-card-wrapper .beach-img-wrapper figure {
	margin-bottom: 0px;
}
.tahnk-wrapp .product-detail .item-total {
	border-top: 0px;
}

/* thank you product ends here */
@media (max-width: 1366px) {
	.thank_you .beach-card-wrapper .bech-content-wrapper {
		margin-left: 10px;
	}
	.thank_you .beach-card-wrapper .bech-content-wrapper a.btn {
		padding: 5px 0px;
	}
	.thank_you .beach-card-wrapper .bech-content-wrapper h4 {
		font-size: 19px;
	}
	.thank_you .beach-card-wrapper .bech-content-wrapper h6 {
		font-size: 13px;
	}
	.thank_you .beach-card-wrapper .bech-content-wrapper h5 {
		font-size: 16px;
	}
	.thank_you .beach-card-wrapper .beach-img-wrapper img {
		width: 147px;
		height: 117px;
	}
	.thank_you .cart-box {
		padding: 30px 35px 20px 35px;
	}
}
@media (max-width: 1200px) {
	.thank_you .product-detail {
		padding: 30px 10px;
		max-width: 90%;
	}
	.thank_you .cart-box {
		max-width: 100%;
	}
	.thank_you .cart-box {
		padding: 45px 30px 10px 20px;
		max-width: 100%;
	}
}
@media (max-width: 575px) {
	.thank_you {
		padding-bottom: 60px;
	}
	.thank_you .heading_wrapper h4 {
		font-size: 20px;
		line-height: 30px;
	}
	.thank_you .heading_wrapper .note {
		font-size: 18px;
	}
	.thank_you .product-detail .item-total h4 {
		font-size: 12px;
	}
	.thank_you .product-detail h4 .value {
		font-size: 15px;
	}
	.thank_you .product-detail .order-total h4 .property {
		font-size: 15px;
	}
}
@media (max-width: 480px) {
	.thank_you .beachs-cards-wrapper {
		flex-direction: column;
	}
	.thank_you .beach-card-wrapper {
		padding: 12px 0px;
	}
}
@media (max-width: 414px) {
	.thank_you .heading_wrapper h2 {
		font-size: 17px;
		line-height: 1;
	}
	.thank_you .heading_wrapper h4 {
		font-size: 15px;
		line-height: 29.9px;
	}
	.thank_you .heading_wrapper .note {
		font-size: 14px;
	}
	.thank_you .cart-box {
		padding: 45px 10px 10px 10px;
		max-width: 100%;
	}
	.thank_you .cart-box {
		margin: 0px auto 30px;
	}
}
@media (max-width: 320px) {
	.thank_you .beach-card-wrapper .beach-img-wrapper img {
		width: 115px;
		height: 97px;
	}
	.thank_you .beach-card-wrapper .bech-content-wrapper a.btn span {
		margin-left: 3px;
	}
	.thank_you .beach-card-wrapper .bech-content-wrapper a.btn {
		padding: 5px 0px;
		font-size: 13px;
	}
}
/* Thank You Css End Here */
