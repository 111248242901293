.recourses-sec .recourse-card-wrapper {
  text-align: center;
}
.recourses-sec {
  padding-top: 70px;
}
.recourses-sec .recourse-card-wrapper .recourse-content-wrapper {
  margin-bottom: 30px;
}
.look-sec .look-content-wrapper {
  padding-bottom: 40px;
}
.look-single-line-wrapper {
  padding-bottom: 40px;
}
