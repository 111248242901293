.artist-news-sec .blog-box-wrapper {
	background: #031637;
	padding: 25px;
	border: 1px solid #30334f;
	margin-bottom: 30px;
}
.artist-news-sec .blog-box-wrapper img {
	height: 327px;
	width: 100%;
	object-fit: cover;
}
.artist-news-sec .blog-box-wrapper .blog-content-wrapper h4 {
	font-size: 30px;
}
.artist-news-sec .blog-box-wrapper .blog-content-wrapper p {
	font-size: 16px;
	font-weight: 400;
}
.artist-news-sec .blog-box-wrapper .blog-content-wrapper a {
	color: var(--extra-color-2);
	font-family: var(--font-default);
}
.side-blog-wrapper .blog-box-wrapper .blog-content-wrapper h4 {
	font-size: 28px !important;
}
.side-blog-wrapper .blog-box-wrapper {
	padding: 35px 25px;
}
.artist-news-sec .blog-slide-row {
	margin: 0 auto;
}
.artist-news-sec .slick-prev:before {
	content: "\f060";
	font-family: "FontAwesome";
}
.artist-news-sec .slick-prev {
	background: var(--extra-color-2);
	height: 53px;
	top: 36%;
	width: 53px;
	border-radius: 3px;
	left: -12px;
	z-index: 9;
}
.artist-news-sec .slick-next:before {
	content: "\f061";
	font-family: "FontAwesome";
}
.artist-news-sec .slick-next {
	background: var(--extra-color-2);
	height: 53px;
	top: 36%;
	width: 53px;
	border-radius: 3px;
	right: -12px;
	z-index: 9;
}
.recourses-sec .recourse-card-wrapper .about-img-wrapper {
	position: relative;
}
.recourses-sec .recourse-card-wrapper .about-img-wrapper img.frame-img {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.artist-news-sec .blog_box .blog-detail {
	border: 1px solid #30334f;
	background: #004c6638;
	padding: 25px 20px;
	text-align: left;
}
/* responsive starts here */
@media (max-width: 1024px) {
	.side-blog-wrapper .blog-box-wrapper .blog-content-wrapper h4 {
		font-size: 21px !important;
	}
}
@media (max-width: 480px) {
	.artist-news-sec .blog-box-wrapper .blog-content-wrapper h4 {
		font-size: 23px;
	}
	.artist-news-sec .blog-box-wrapper .blog-content-wrapper p {
		font-size: 14px;
	}
	.artist-news-sec .blog-box-wrapper {
		padding: 14px;
	}
	.artist-news-sec .blog-slide-row {
		width: 100%;
	}
	.artist-news-sec .blog_box .blog-detail h2 {
		font-size: 18px;
	}
	.artist-news-sec .slick-next {
		background: var(--extra-color-2);
		height: 32px;
		top: 36%;
		width: 32px;
		border-radius: 3px;
		right: 4px;
		z-index: 9;
	}
	.artist-news-sec .slick-prev {
		background: var(--extra-color-2);
		height: 32px;
		top: 36%;
		width: 32px;
		border-radius: 3px;
		left: 4px;
		z-index: 9;
	}
}
/* responsive ends here */
