/* Blog Css Start Here */
.blog_sec {
	padding-top: 50px;
	padding-bottom: 150px;
	background-image: url("../img/login-bg.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top center;
}
.all_blogs {
	padding-top: 40px;
}
.blog_box {
	margin-bottom: 25px;
}
.blog_box .blog-detail {
	border: 1px solid #30334f;
	background: #004c6638;
	padding: 25px 20px;
}
.blog_box .blog-img img {
	height: 300px;
	object-fit: cover;
	width: 100%;
}
.blog_box .blog-img {
	position: relative;
}
.blog_box .blog-img .date {
	position: absolute;
	top: 25px;
	right: 25px;
	width: 66px;
	background: var(--extra-color-3);
	padding: 13px 9px;
	border-radius: 5px;
}
.blog_box .blog-img .date h4 {
	font-size: 22px;
	text-align: center;
	line-height: 22px;
	margin: 0;
}
.blog_box .blog-img .date h4 span {
	font-size: 14px;
}
.blog_box .blog-img figure {
	margin: 0;
}
.blog_box .blog-detail .posted {
	padding-bottom: 15px;
	padding-top: 15px;
}
.blog_box .blog-detail .posted h5 {
	font-size: 16px;
	line-height: 26px;
	font-family: var(--poppins);
	font-weight: 400;
	color: #ffffffb8;
}
.blog_box .blog-detail .posted h5 span,
.blog_box .blog-detail .posted h5 span a {
	color: var(--extra-color-2);
}
.blog_box .blog-detail .blog-description h2 {
	font-size: 33px;
}
.blog_box .blog-detail .blog-description p {
	font-size: 14px;
	color: #ffffff;
	max-width: 90%;
	opacity: 70%;
}
.sidebar {
	padding-top: 40px;
}
.sidebar .sidebar-box {
	border: 1px solid #ffffff7a;
	border-radius: 10px;
	padding: 15px 0;
	margin-bottom: 40px;
}
.sidebar .sidebar-box .list {
	margin: 0;
	padding: 0;
	list-style: none;
}
.sidebar .sidebar-box .title {
	border-bottom: 1px solid #ffffff6b;
	padding: 5px 25px 10px;
}
.sidebar .sidebar-box .title h4 {
	font-size: 20px;
	font-family: var(--font-semibold);
}
.sidebar .sidebar-box .list li {
	border-bottom: 1px solid #ffffff6b;
	font-family: "Handel-Gothic";
}
.sidebar .sidebar-box .list li a {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px 30px;
}
.sidebar .sidebar-box .list li a .name {
	padding-left: 20px;
	position: relative;
	font-family: var(--font-default);
}
.sidebar .sidebar-box .list li a .name:before {
	content: "";
	width: 5px;
	height: 5px;
	background: #00bbff;
	position: absolute;
	left: 0;
	top: 9px;
}
.sidebar .sidebar-box .list li:last-child {
	border-bottom: 0;
}
.sidebar .sidebar-box .list li a .count {
	color: #ffffff8f;
	font-family: var(--poppins);
	margin-left: 10px;
}
.sidebar .recent-news li {
	padding: 10px 0px;
}
.sidebar .sidebar-box .list li a .img-box img {
	max-width: 70px;
}
.sidebar .sidebar-box .list li .content {
	margin-left: 15px;
}
.sidebar .sidebar-box .list li .content .date {
	color: #00bbffc7;
	display: flex;
	margin: 0px 0px 4px 0px;
	align-items: center;
}
.sidebar .sidebar-box .list li .content .date i {
	margin-right: 8px;
}
.sidebar .sidebar-box .list li .content .description {
	margin-bottom: 0;
}
.blog-detail .blog_box .blog-img .date {
	left: 20px;
	right: unset;
}
.blog-detail .blog-img img {
	height: 400px;
}
.blog-detail .blog-detail {
	padding: 30px 0;
	margin: 0;
	background: transparent;
	border: 0;
}
.blog_sec .blog-description p {
	line-height: 24px;
	opacity: 0.8;
	max-width: 100%;
}
@media (max-width: 991px) {
	.blog_sec {
		padding-bottom: 50px;
	}
	.blog_sec .row {
		flex-direction: column-reverse;
	}
	.sidebar .sidebar-box .list li a {
		justify-content: left;
	}
}
@media (max-width: 768px) {
	.all_blogs {
		padding-top: 0px;
	}
	.blog_box .blog-detail .blog-description h2 {
		font-size: 25px;
		line-height: 35px;
	}
}
@media (max-width: 575px) {
	.blog_box .blog-detail .blog-description h2 {
		font-size: 18px;
		line-height: 25px;
	}
	.blog_box .blog-detail {
		padding: 10px 10px;
	}
	.blog-detail .blog_box .blog-img .date {
		left: 10px;
		top: 10px;
	}
	.blog-detail .blog-img img {
		height: 300px;
	}
	.blog_box .blog-img img {
		height: 200px;
	}
	.sidebar .sidebar-box .list li a {
		padding: 15px 10px;
	}
	.blog_box .blog-detail .blog-description p {
		max-width: 100%;
		font-size: 11px;
		line-height: 18px;
	}
}
@media (max-width: 320px) {
	.blog-detail .blog-img img {
		height: 250px;
	}
	.blog_box .blog-detail .blog-description p {
		max-width: 100%;
		text-align: justify;
	}
}
/* Blog Css End Here */
