.profile .my-subscription-wrapper {
	border: 1px solid #828496;
	border-radius: 1px;
}
.sub-wrapper {
	padding: 50px 50px;
}
.profile .my-subscription-wrapper .my-subscription-heading-wrapper {
	display: flex;
	align-items: center;
}
.profile .my-subscription-wrapper .my-subscription-heading-wrapper button {
	margin-left: 28px;
	background: transparent;
	border: 0px;
	color: var(--extra-color-2);
	font-family: var(--font-semibold);
	font-size: 15px;
}
.profile .my-subscription-wrapper .form-group {
	margin-bottom: 1rem;
	margin-top: 1rem;
}
.profile .my-subscription-wrapper .form-group .sub-pack-wrapper h6 {
	font-family: var(--font-default);
	font-size: 14px;
	margin: 0px;
	font-weight: 300;
}
.profile .my-subscription-wrapper .form-group .sub-pack-wrapper h5 {
	font-family: var(--font-default);
	font-size: 14px;
	margin: 0px;
	font-weight: 300;
}
.profile .my-subscription-wrapper .form-group .sub-pack-wrapper h3 {
	font-size: 29px;
	margin: 0;
}
.profile .my-subscription-wrapper .form-group .sub-pack-wrapper {
	margin: 20px 0px;
}
.profile .my-subscription-wrapper .divider-wrapper {
	border-bottom: 1px solid var(--default-text-color);
}
.profile .my-subscription-wrapper .divider-wrapper .divide {
	width: 30%;
	border: 1px solid var(--default-text-color);
}
.profile .my-subscription-wrapper .prev-wrapper {
	padding: 50px 50px;
}
.my-subscription-wrapper .prev-wrapper .prev-table-wrapper {
	margin-top: 37px;
}
.profile .my-subscription-wrapper .prev-table-wrapper tr {
	border: 0px;
}
.profile .my-subscription-wrapper .prev-table-wrapper th,
.profile .my-subscription-wrapper .prev-table-wrapper td {
	border: 0px;
	padding: 20px 0px;
}
.profile .my-subscription-wrapper .prev-table-wrapper th {
	color: var(--default-text-color);
	font-family: var(--font-bold);
	font-size: 18px;
}
.profile .my-subscription-wrapper .prev-table-wrapper td {
	color: var(--default-text-color);
	font-family: var(--font-semibold);
	font-size: 15px;
	font-weight: 600 !important;
}
/* responsive starts here */
@media (max-width: 480px) {
	.sub-wrapper {
		padding: 30px 20px;
	}
	.profile .my-subscription-wrapper .my-subscription-heading-wrapper {
		flex-direction: column;
		align-items: flex-start;
	}
	.profile
		.my-subscription-wrapper
		.my-subscription-heading-wrapper
		button.change-sub {
		margin: 0px;
		padding: 0px;
		padding-top: 15px;
	}
	.profile .my-subscription-wrapper .form-group .sub-pack-wrapper {
		margin: 10px 0px;
	}
	.profile .my-subscription-wrapper .form-group .sub-pack-wrapper h6 {
		margin-bottom: 10px;
	}
	.profile .my-subscription-wrapper .form-group .sub-pack-wrapper h3 {
		margin-bottom: 10px;
	}
	.profile .my-subscription-wrapper .prev-wrapper {
		padding: 30px 20px;
	}
	.profile .my-subscription-wrapper .prev-table-wrapper th {
		font-size: 11px;
		text-align: center;
	}
	.profile .my-subscription-wrapper .prev-table-wrapper td {
		font-size: 10px;
		text-align: center;
	}
	.profile .my-subscription-wrapper .prev-wrapper .prev-heading-wrapper h3 {
		font-size: 20px;
	}
	.profile .my-subscription-wrapper .prev-table-wrapper th span.date {
		width: 100px;
		display: block;
	}

	.profile .my-subscription-wrapper .prev-table-wrapper th span.plan {
		width: 122px;
		display: block;
	}

	.profile .my-subscription-wrapper .prev-table-wrapper th span.payment {
		width: 122px;
		display: block;
	}

	.profile .my-subscription-wrapper .prev-table-wrapper th span.expire {
		width: 122px;
		display: block;
	}
}
/* responsive ends here */
