/* Testimonials Css Start Here */
.testimonials {
	padding-top: 131px;
	padding-bottom: 150px;
	background-image: url("../img/login-bg.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top center;
}
.all-testimonials {
	padding: 100px 0 0;
}
.all-testimonials .testimonial-box {
	margin: 0px auto;
	width: 80%;
	border: 1px solid #ffffff59;
	border-radius: 8px;
	display: flex;
	padding: 30px 20px;
	margin-bottom: 30px;
}
.all-testimonials .testimonial-box .name-box {
	min-width: 200px;
	text-align: center;
}
.all-testimonials .testimonial-box .txt h4 {
	line-height: 24px;
}
.all-testimonials .testimonial-box .para {
	padding-top: 15px;
}
.all-testimonials .testimonial-box .txt p {
	margin: 0;
	color: var(--extra-color-2);
}
@media (max-width: 991px) {
	.all-testimonials {
		padding: 50px 0 0;
	}
	.testimonials {
		padding-bottom: 50px;
	}
	.all-testimonials .testimonial-box {
		display: block;
		text-align: center;
		width: 100%;
	}
	.all-testimonials .testimonial-box .para p {
		text-align: justify;
		text-align-last: center;
	}
}
/* Testimonials Css End Here */
