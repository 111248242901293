.sitemap-sec {
	padding-bottom: 150px;
}
.sidebar-text-and-img-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.sitemap-sec .site-bar-wrapper {
	text-align: center;
}
.sitemap-sec .site-bar-wrapper .sitemap-card-wrapper {
	position: relative;
	width: 209px;
	height: 181px;
	margin: 0px auto;
	margin-bottom: 20px;
}
.sitemap-sec .site-bar-wrapper .sitemap-card-wrapper .site-content-wrapper {
	position: absolute;
	top: 32%;
	text-align: center;
	width: 100%;
}
.sitemap-sec .site-bar-wrapper .sitemap-card-wrapper .site-content-wrapper h4 {
	font-size: 25px;
}
.sitemap-sec .site-bar-wrapper .sitemap-card-wrapper .site-content-wrapper p {
	font-size: 12px;
}
.sitemap-sec .site-cards-wrapper .site-card-wrapper {
	height: 265px;
	width: 309px;
	position: relative;
	margin: 0px auto;
}
.sitemap-sec .site-cards-wrapper .site-card-row-wrapper.first-row {
	margin-top: -271px;
}
.sitemap-sec .site-cards-wrapper .site-card-wrapper .site-content-wrapper {
	position: absolute;
	top: 40%;
	margin: 0 auto;
	width: 100%;
	text-align: center;
}
.sitemap-sec .site-cards-wrapper .site-card-wrapper .site-content-wrapper p {
	font-size: 10px;
}
/* responsive starts here */
@media (max-width: 1280px) {
	.sitemap-sec .site-cards-wrapper .site-card-wrapper {
		height: 265px;
		width: 276px;
		margin: 0px;
	}
	.sitemap-sec .site-cards-wrapper .site-card-wrapper .site-content-wrapper {
		position: absolute;
		top: 27%;
	}
}
@media (max-width: 768px) {
	.sitemap-sec .site-cards-wrapper .site-card-wrapper {
		margin: 0px auto;
	}
	.sidebar-text-and-img-wrapper .img-wrapper {
		display: none;
	}
	.sitemap-sec .site-bar-wrapper {
		flex: 0 0 100%;
		margin-top: 17px !important;
		padding: 0px !important;
	}
	.site-cards-wrapper .site-arrow-img1-wrapper img {
		opacity: 0;
		margin: 0px;
	}
	.site-arrow-img2-wrapper img {
		opacity: 0;
		margin: 0px;
		display: none;
	}
	.site-cards-wrapper .site-arrow-img1-wrapper::before {
		content: "\f063";
		font-family: "FontAwesome";
		font-size: 61px;
		color: #f0f0f0;
	}
	.site-arrow-img2-wrapper::before {
		content: "\f063";
		font-family: "FontAwesome";
		font-size: 61px;
		color: #f0f0f0;
	}
	.site-card-row-wrapper:last-child:after {
		content: "\f063";
		font-family: "FontAwesome";
		font-size: 61px;
		color: #f0f0f0;
		width: 100px;
		height: 100px;
		display: block;
		margin: 0px auto;
		text-align: center;
	}
}
@media (max-width: 480px) {
	.sitemap-sec {
		padding-top: 44px;
	}
	.sitemap-sec .site-cards-wrapper .site-card-wrapper {
		margin: 0 auto;
	}
	.sidebar-text-and-img-wrapper {
		display: block;
	}
	.sidebar-text-and-img-wrapper .img-wrapper {
		display: none;
	}
	.sitemap-sec .site-cards-wrapper .site-card-row-wrapper.first-row {
		margin-top: -283px;
	}
	.sitemap-sec .site-arrow-img1-wrapper {
		/* opacity: 0; */
	}
	.sitemap-sec .site-arrow-img2-wrapper {
		/* opacity: 0; */
	}
}
/* responsive ends here */
