.faq {
  background-image: url("../img/login-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
  padding: 80px 0px;
}
.faq .heading-row {
  margin-bottom: 34px;
}
.faq-wrapper {
  border: 1px solid #777575;
  border-radius: 7px;
}
.faq-wrapper .accordion-item {
  background: transparent;
  border: 0px;
}
.faq-wrapper .accordion-item h2 {
  background: transparent;
  border: 0px;
}
.faq-wrapper .accordion-item button.accordion-button {
  background: transparent;
  border-bottom: 1px solid #777575 !important;
  color: var(--default-text-color);
  font-family: var(--font-bold);
  font-size: 18px;
  padding: 23px 20px;
}
.faq-wrapper .accordion-item .accordion-collapse .accordion-body {
  border: 0px !important;
  color: var(--default-text-color);
  font-family: var(--font-light);
  font-weight: 300;
  font-size: 14px;
}
.faq .accordion-button::after {
  content: "";
  background: url("../img/plus.png");
  background-size: contain;
  height: 12px;
  width: 12px;
}
.faq .accordion-button:not(.collapsed)::after {
  background: url("../img/miuns.png");
  content: "";
  height: 4px;
  width: 12px;
  position: relative;
}
