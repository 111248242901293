.artist-news-sec {
	background: transparent;
}
.artist-news-sec .testimonials-wrapper h1 {
	font-size: 40px;
	line-height: 1.3;
}
.artist-news-sec .blog_box .blog-detail h2 {
	font-size: 25px;
	font-weight: 700;
}
.artist-news-sec .blog_box .blog-detail p {
	opacity: 80%;
	margin-bottom: 10px;
}
.artist-news-sec .blog_box .blog-detail a {
	color: var(--extra-color-2);
	font-size: 16px;
}
.news-detail {
	padding-top: 70px;
	padding-bottom: 70px;
}

.news-detail img {
	height: 315px;
	object-fit: cover;
}
.news-detail p {
	font-size: 15px;
	margin-bottom: 32px;
	margin-top: 20px;
	line-height: 24px;
	opacity: 80%;
}
.news-detail .news-detail-heading-wrapper h2 {
	margin-top: 40px;
}
.news-detail .ps-lg-5 {
	padding-left: 30px !important;
}
.go-to-previous-page {
	margin-top: 54px;
}

.go-to-previous-page a {
	font-family: var(--font-bold);
	background: var(--extra-color-3);
	padding: 9px 20px;
}
@media (max-width: 1440px) {
	.news-detail .ps-lg-5 {
		padding-left: 30px !important;
	}
}
@media (max-width: 1366px) {
	.news-detail .ps-lg-5 {
		padding-left: 20px !important;
	}
}
@media (max-width: 768px) {
	.news-detail .ps-lg-5 {
		padding-left: 0px !important;
	}
}
