.code-sec {
	padding-top: 70px;
	padding-bottom: 70px;
}
.code-sec .conduct-heading-wrapper {
	padding-bottom: 30px;
}
.code-sec .conduct-content-wrapper h4 {
	font-size: 25px;
	font-weight: 600;
	font-family: var(--font-semibold);
}
.code-sec .conduct-content-wrapper p {
	letter-spacing: 1px;
	font-size: 16px;
	font-weight: 300 !important;
	margin-top: 20px;
}
.code-sec .conduct-content-wrapper {
	margin-bottom: 43px;
}
