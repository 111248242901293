/* about page header starts here */
.about-page-header {
	background-image: url("../img/login-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	padding: 137px 0px 100px 0px;
}
/* about page header ends here */
/* our mission starts here */
.our-mission {
	padding: 0px 0px 100px 0px;
}
.our-mission .mission-content-wrapper p {
	line-height: 2;
	font-size: 12px;
}
/* our mission ends here */
/* our vision starts here */
.our-vision {
	padding: 0px 0px 100px 0px;
}
.our-vision .mission-content-wrapper p {
	line-height: 2;
}
/* our vision ends here */
/* our story starts here */
.our-story .divider-wrapper {
	border-bottom: 1px solid var(--default-text-color);
}
.our-story .divider-wrapper .divider-line {
	width: 30%;
	border: 2px solid var(--default-text-color);
	margin: 0px auto;
}
.our-story .story-heading-wrapper {
	text-align: center;
}
.our-story .story-content-wrapper {
	border-top: 1px solid var(--default-text-color);
	border-left: 1px solid var(--default-text-color);
	border-right: 1px solid var(--default-text-color);
	padding: 30px 50px;
	width: 90%;
	margin: 0px auto;
	margin-top: 30px;
}
.our-story .story-content-wrapper p {
	line-height: 1.8;
}
.moonscape-team .teams-main-heading-wrapper {
	text-align: center;
	padding-bottom: 52px;
}
/* our story ends here */
/* Team starts here */
.moonscape-team .teams-main-heading-wrapper {
	text-align: center;
	padding-bottom: 52px;
}
.moonscape-team .team-card-wrapper .team-content-wrapper {
	background: #004c66;
	padding: 10px;
	text-align: center;
	width: 90%;
	margin: 0px auto;
	margin-top: -32px;
	position: relative;
}
.moonscape-team .team-card-wrapper .team-content-wrapper h4 {
	font-size: 25px;
}
.moonscape-team .team-card-wrapper .team-content-wrapper p {
	font-size: 13px;
}
.moonscape-team .team-card-wrapper .team-img-wrapper figure {
	margin: 0px;
}
.team-card-wrapper .team-img-wrapper img {
	width: 100%;
	height: 341px;
	object-fit: cover;
	border-radius: 3px;
	border: 1px solid #c2cbce;
}
.moonscape-team {
	padding-bottom: 60px;
}
.moonscape-team .divider-wrapper {
	border-bottom: 1px solid var(--default-text-color);
}
.moonscape-team .divider-wrapper .divider-line {
	width: 30%;
	border: 2px solid var(--default-text-color);
	margin: 0px auto;
}
/* Team ends here */
/* responsive starts here */
@media (max-width: 768px) {
	.moonscape-team .team-card-wrapper {
		margin-bottom: 30px;
	}
}
@media (max-width: 480px) {
	.about-page-header {
		padding: 105px 0px 20px 0px;
	}
	.our-story .story-content-wrapper {
		padding: 18px 21px;
		width: 100%;
	}
}
/* responsive ends here */
