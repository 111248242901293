.artist-masnary {
	padding-bottom: 100px;
	padding-top: 50px;
}
.artist-masnary .masonry_box .image_box .bottom-bar {
	padding: 16px;
}
.artist-masnary .masonry_box .image_box .bottom-bar {
	bottom: -18px;
}
.artist-masnary .masonry_box .image_box .bottom-bar h3 {
	font-size: 30px;
}
.artist-masnary .masonry_box {
	padding-top: 23px;
}
